import { useQuery } from "@tanstack/react-query";
import { subYears } from "date-fns";

import { getEventLocationCoordinates, getEventWeatherArchiveData, getEventWeatherForecast } from "@/axios/get-request";
import { formatDate, isDateWithin14daysRange, reduceCurrentYearByOne } from "@/helper";
import getWeatherCondition from "@/utils/getWeatherCondition";
import weather_codes from "@/json/weather_code.json";
import type { eventType } from "@/types";

type ceremonyType = eventType["ceremonies"][0];

export default function useEventWeather(_ceremony: ceremonyType | null) {
    const ceremony = _ceremony as ceremonyType;
    const ceremonyLocationLowercase = ceremony?.location ? ceremony?.location?.toLowerCase() : "";
    const ceremonyLocation =
        ceremony?.location && (ceremonyLocationLowercase?.includes("island") || ceremonyLocationLowercase?.includes("mainland")) ?
            "Lagos" :
            ceremony?.location;


    const { data, status: locationStatus } = useQuery({
        queryKey: [`getEventLocationCoordinates-${ceremony?.location}`],
        queryFn: () => getEventLocationCoordinates(ceremonyLocation),
        enabled: !!(ceremonyLocation && ceremony?.date),
    });
    const enableWeatherForecastSearch = data?.result?.results ? data?.result.results[0]?.latitude : false;
    const ceremonyDate = ceremony?.date as Date;
    const eventDate = ceremonyDate ? (formatDate(ceremonyDate, "yyyy-mm-dd") as string) : null;
    const eventOccurrence = ceremonyDate ? isDateWithin14daysRange(ceremonyDate) : null;

    /**
     * if eventOccurrence is false, use weather forecast endpoint
     * if eventOccurrence is true, use weather archive endpoint
     */
    const fetchWeatherForecast = enableWeatherForecastSearch && !eventOccurrence && ceremony?.location && ceremony?.date;
    const { data: forecastData, status } = useQuery({
        queryKey: [`getEventWeatherForecast-${ceremony?.location}`],
        queryFn: () =>
            getEventWeatherForecast(
                {
                    latitude: data?.result?.results[0].latitude,
                    longitude: data?.result?.results[0].longitude,
                },
                eventDate,
            ),
        enabled: !!fetchWeatherForecast,
    });

    const weatherArchiveDate = ceremonyDate ? reduceCurrentYearByOne(ceremonyDate) : null;
    const fetchWeatherArchive = enableWeatherForecastSearch && eventOccurrence && ceremony?.location;
    const weatherDate = weatherArchiveDate ? subYears(new Date(weatherArchiveDate), 1) : null;

    const formatWeatherArchiveDate = weatherDate ? (formatDate(new Date(weatherDate), "yyyy-mm-dd") as string) : null;
    const { data: archiveWeatherData, status: archiveWeatherStatus } = useQuery({
        queryKey: [`getEventLocationArchiveWeatherData-${ceremony?.location}`],
        queryFn: () =>
            getEventWeatherArchiveData(
                {
                    latitude: data?.result?.results[0].latitude,
                    longitude: data?.result?.results[0].longitude,
                },
                formatWeatherArchiveDate,
            ),
        enabled: !!fetchWeatherArchive,
    });


    const weatherCondition =
        status === "success" ?
            weather_codes.filter(
                (weather) =>
                    forecastData?.result.current_weather.weathercode >= weather.min &&
                        weather.max <= forecastData?.result.current_weather.weathercode,
            )[0] :
            null;

    const weatherStatus =
        (locationStatus === "loading" || (fetchWeatherForecast && status === "loading") ||
        (fetchWeatherArchive && archiveWeatherStatus === "loading")) ?
            true :
            false;


    const eventForecastTemperature = fetchWeatherArchive ?
        archiveWeatherData?.result?.daily?.temperature_2m_mean[0] :
        fetchWeatherForecast ?
            forecastData?.result?.current_weather?.temperature :
            null;

    const archiveWeatherForecastCode = archiveWeatherData?.result?.daily?.weathercode[0] as number;

    const eventWeatherCode = fetchWeatherArchive ?
        { min: archiveWeatherForecastCode, max: Number(archiveWeatherForecastCode) + 3 } :
        fetchWeatherForecast ?
            weatherCondition :
            null;

    const forecastRequestStatus =
        (fetchWeatherForecast && status === "success") || (fetchWeatherArchive && archiveWeatherStatus === "success") ? true : false;

    const weatherIcon = eventWeatherCode ? getWeatherCondition(eventWeatherCode) : null;

    return { weatherStatus, weatherCondition, weatherIcon, forecastRequestStatus, eventForecastTemperature };
}
