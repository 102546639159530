import { Link } from "react-router-dom";
import { memo, useRef, useState } from "react";

import MoreIcon from "@/assets/icon/MoreIcon";
import { useFilter } from "@/hooks/useFilter";
import { useBudgetDropdown } from "@/hooks/useBudgetDropdown";
import { useEvent } from "@/hooks/useEvent";
import useClickOutside from "@/hooks/useClickOutside";
import { CollaboratorPaymentPlan, CollaboratorPlanStatus, eventType } from "@/types";

interface Props {
    index: number;
    event: eventType;
    userEmail?: string;
    invalidateQueries: () => void;
    showDuplicateEventModal: (eventName: { id: string; name: string }) => void;
    isCurrentUserACollaborator: boolean;
}

function EventCardComponent({ event, userEmail, index, invalidateQueries, showDuplicateEventModal, isCurrentUserACollaborator }: Props) {
    const { setSelectedEventId } = useEvent();
    const [dropdown, setDropdown] = useState(false);
    const { setCeremony } = useFilter();
    const { setBudgetDropdown } = useBudgetDropdown();
    const ref = useRef(null);
    useClickOutside(ref, closeDropdown);

    const ceremonies = event.ceremonies;
    const ceremonyText = ceremonies && ceremonies.length > 1 ? "Ceremonies" : "Ceremony";

    function viewEventHandler(eventId: string) {
        setSelectedEventId(eventId);
        setCeremony({
            name: "All Ceremonies",
            id: "all",
        });
        setBudgetDropdown({
            categoryId: null,
            costItemId: null,
        });
        invalidateQueries();
    }

    function closeDropdown() {
        setDropdown(false);
    }

    const eventId = `view_event_${index}`;
    const eventUrl = `/dashboard?event_id=${event.id}`;

    const eventCardBg = { backgroundColor: event.event_bg };

    const collaborator = event?.collaborators?.filter((item) => item.email === userEmail)[0];

    const eventStyle =
        collaborator?.plan_status !== CollaboratorPlanStatus.ACTIVE &&
        collaborator?.payment_plan === CollaboratorPaymentPlan.PAID &&
        isCurrentUserACollaborator ?
            "disable_event" :
            "";

    return (
        <div id={eventId} className={`position-relative event_card ${eventStyle} card border-0`}>
            {collaborator &&
                collaborator?.plan_status !== CollaboratorPlanStatus.ACTIVE &&
                collaborator?.payment_plan === CollaboratorPaymentPlan.PAID &&
                isCurrentUserACollaborator && <label className="disabled_event_label">Plan expired, reach out to event owner</label>}
            {collaborator?.plan_status === CollaboratorPlanStatus.ACTIVE || event?.collaborators?.length > 0 ? (
                <Link
                    id={event.id}
                    to={eventUrl}
                    key={event.id}
                    data-testid={eventId}
                    className="event_card card border-0 relative"
                    onClick={() => viewEventHandler(event.id)}>
                    {event.event_image ? (
                        <img src={event.event_image} alt={event.event_name} />
                    ) : (
                        <div className="profile_banner" style={eventCardBg} />
                    )}
                </Link>
            ) : (
                <div className="event_card card border-0 relative">
                    {event.event_image ? (
                        <img src={event.event_image} alt={event.event_name} />
                    ) : (
                        <div className="profile_banner" style={eventCardBg} />
                    )}
                </div>
            )}
            <div className="content d-flex justify-content-between">
                {collaborator?.plan_status === CollaboratorPlanStatus.ACTIVE || event?.collaborators?.length > 0 ? (
                    <Link
                        id={event.id}
                        to={eventUrl}
                        key={event.id}
                        className="event_card border-0 card relative"
                        onClick={() => viewEventHandler(event.id)}>
                        <h6>{event.event_name}</h6>
                        {ceremonies && (
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="m-0">
                                    {ceremonies?.length} {ceremonyText}
                                </p>
                            </div>
                        )}
                    </Link>
                ) : (
                    <div className="event_card border-0 card relative">
                        <h6>{event.event_name}</h6>
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="m-0">
                                {ceremonies?.length} {ceremonyText}
                            </p>
                        </div>
                    </div>
                )}
                <button id="duplicate_event_button" className="more_icon bg-transparent border-0 px-0" onClick={() => setDropdown(true)}>
                    <MoreIcon />
                </button>
                {dropdown && (collaborator?.plan_status === CollaboratorPlanStatus.ACTIVE || event?.collaborators?.length > 0) && (
                    <div className="dropdown d-flex flex-column align-items-start bg-white px-3 py-2" ref={ref}>
                        <button
                            className="bg-transparent border-0 px-0"
                            onClick={() =>
                                showDuplicateEventModal({
                                    name: event.event_name,
                                    id: event.id,
                                })
                            }>
                            Duplicate Event
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

const EventCard = memo(EventCardComponent);
export default EventCard;
