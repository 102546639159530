import { differenceInCalendarDays } from "date-fns";

import useChecklistItem from "@/hooks/useChecklistItem";
import type { eventType, todoType } from "@/types";

interface Props {
	firstTwoUpcomingTask: todoType;
	activeEvent: eventType[] | null;
}

interface UpcomingTaskItemProps {
	index: number;
	task: todoType[0];
	firstTwoUpcomingTask: todoType;
	activeEvent: eventType[] | null;
}

function UpcomingTaskItem({ index, task, firstTwoUpcomingTask, activeEvent }: UpcomingTaskItemProps) {
    const { onCompleteTaskHandler } = useChecklistItem(task, activeEvent);

    const style = index === 0 && firstTwoUpcomingTask.length > 1 ? "border-bottom" : "";
    const taskDueNumber = task.due ? differenceInCalendarDays(new Date(task.due), new Date()) : null;
    const taskDuePeriod =
		taskDueNumber === 0 ? "today" : taskDueNumber === 1 ? "tomorrow" : taskDueNumber && taskDueNumber > 1 ? `in ${taskDueNumber} days` : "";
    const taskDueText = task.due ? `Due ${taskDuePeriod}` : null;

    return (
        <li className={`${style} pb-2 pt-4 d-flex align-items-start`}>
            <input type="checkbox" checked={task.isDone} className="mt-1" onChange={onCompleteTaskHandler} />
            <div className="d-flex ml-2 flex-column">
                <h6 className="mt-0">{task.name}</h6>
                {task.due && <p>{taskDueText}</p>}
            </div>
        </li>
    );
}

export default function UpcomingTask({ firstTwoUpcomingTask, activeEvent }: Props) {
    return (
        <ul className="d-flex flex-column mt-3 p-0">
            {firstTwoUpcomingTask.map((task, index) => (
                <UpcomingTaskItem key={index} index={index} task={task} firstTwoUpcomingTask={firstTwoUpcomingTask} activeEvent={activeEvent} />
            ))}
        </ul>
    );
}
