/* eslint-disable no-constant-condition */
import { ToastContainer } from "react-toastify";
import type { ReactNode } from "react";

import logo from "@/assets/img/logo.png";
import grid1 from "@/assets/img/grid_1.png";
import grid2 from "@/assets/img/grid_2.png";
import grid3 from "@/assets/img/grid_3.png";
import grid4 from "@/assets/img/grid_4.png";
import grid5 from "@/assets/img/grid_5.png";
import grid6 from "@/assets/img/grid_6.png";
import grid7 from "@/assets/img/grid_7.png";
import grid8 from "@/assets/img/grid_8.png";
import grid9 from "@/assets/img/grid_9.png";
import "@/styles/onboarding.scss";

interface Props {
  children: ReactNode;
  className?: string;
  isFetchingData?:boolean
}

const imageGrid = [grid1, grid2, grid3, grid4, grid5, grid6, grid7, grid8, grid9];

export default function OnboardingLayout({
    children,
    className,
}: Props) {
    return (
        <>
            <ToastContainer autoClose={2000} newestOnTop={false} closeOnClick rtl={false} />
            <div className={`onboarding_layout d-flex flex-lg-row flex-column align-items-start px-0 ${className}`}>
                <div className="col-lg-4 onboarding-grid col-12 px-0">
                    <div className="logo">
                        <img src={logo} alt="Logo" />
                    </div>
                    {imageGrid.map((imageLink, index) => (
                        <img src={imageLink} key={index} className={`grid_${index}`} />
                    ))}
                </div>
                <div className="onboarding_body col-lg-8 col-12 pl-lg-5 pt-lg-5 pt-4">
                    <h3 className="mt-lg-4 mt-0">Unlock your dream event in a few steps</h3>
                    {children}
                </div>
            </div>
        </>

    );
}
