import axios from "axios";

import { RequestErrorType } from "@/types";
import { PostResponseWithoutDataType } from "@/data/types";

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export async function deleteCostItem(id: string, categoryId: string, eventId: string): Promise<PostResponseWithoutDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event/${eventId}/category/${categoryId}/cost-item/${id}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function deleteCostItemCategory(categoryId: string, eventId: string): Promise<PostResponseWithoutDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event/${eventId}/category/${categoryId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function deleteChecklist(ceremonyId: string, checklistId: string, eventId: string): Promise<PostResponseWithoutDataType> {
    try {
        const data = await axios.delete(`${BASE_URL}/api/v1/event/${eventId}/checklist/${ceremonyId}/${checklistId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}
