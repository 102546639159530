import { useEffect, useLayoutEffect } from "react";
import ReactGA from "react-ga4";
import { Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { InitializeReactGA } from "@/analytics";
import Calculator from "@/pages/calculator";
import BudgetTrackerPage from "@/pages/budget-tracker";
import Checklist from "@/pages/Checklist";
import Signup from "@/pages/signup";
import Login from "@/pages/login";
import MyEvents from "@/pages/myevents";
import Profile from "@/pages/profile";
import EditProfile from "@/pages/edit-profile";
import useAuthGuard from "@/hooks/useAuthGuard";
import GuardedRoute from "@/components/GuardedRoute";
import UserProfile from "@/pages/user-profile";
import AuditsPage from "@/pages/audits";
import ResetPassword from "@/pages/reset-password";
import PricingPage from "./pages/pricing";
import OnboardingBudgetDetails from "./pages/onboarding/budget-details";
import OnboardingEventDetails from "./pages/onboarding/event-details";
import InviteEventCollaborator from "./pages/onboarding/invite-event-collaborator";
import SaveOnboardingProfile from "./pages/onboarding/save-profile";
import ViewEventBudget from "./pages/onboarding/view-event-budget";
import CalculateEventBudget from "./pages/onboarding/calculate-event-budget";
import AsoebiTracker from "./pages/asoebi-tracker";
import CompleteGuestQuestionaire from "./pages/asoebi-tracker/complete-questionnaire";
import CreateQuestionaire from "./pages/asoebi-tracker/create-questionnaire";
import Questionnaire from "./pages/asoebi-tracker/questionnaire";
import DashboardAuth from "./pages/asoebi-tracker/admin-dashboard/auth";
import AsoebiAdminList from "./pages/asoebi-tracker/admin-dashboard/asoebi-admin-list";
import AsoebiAdminPayments from "./pages/asoebi-tracker/admin-dashboard/payment";
import AsoebiAdminQuestionnaires from "./pages/asoebi-tracker/admin-dashboard/questionnaires";
import UserDashboard from "./pages/asoebi-tracker/user-dashboard";
import AsoebiLogin from "./pages/asoebi-tracker/user-dashboard/auth/login";
import QuestionnaireCompleted from "./pages/asoebi-tracker/questionnaire-completed";
import QuestionnaireAnswered from "./pages/asoebi-tracker/questionnaire-answered";
import AsoebiPage from "./pages/asoebi";
import AsoebiAdminLogin from "./pages/asoebi-tracker/user-dashboard/auth/admin-login";
import InitializeHotjar from "./InitializeHotjar";
import "@/styles/index.scss";
import "@/styles/utilities.scss";
import "@/App.css";

function App() {
    const authGuard = useAuthGuard();
    const location = useLocation();

    useEffect(() => {
        InitializeReactGA(ReactGA);
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname, location.search]);

    return (
        <>
            <InitializeHotjar />
            <Routes>
                <Route path="/" element={<Calculator />} />
                <Route path="/budget" element={<Calculator />} />
                <Route path="/onboarding/event-details" element={<OnboardingEventDetails />} />
                <Route path="/onboarding/budget-details" element={<OnboardingBudgetDetails />} />
                <Route path="/onboarding/calculate-event-budget" element={<CalculateEventBudget />} />
                <Route path="/onboarding/view-event-budget" element={<ViewEventBudget />} />
                <Route path="/onboarding/invite-event-collaborator" element={<InviteEventCollaborator />} />
                <Route path="/onboarding/save-profile" element={<SaveOnboardingProfile />} />
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/signup" element={<Signup />} />

                {/* asoebi questionnaire organizer */}
                <Route path="/asoebi" element={<AsoebiPage />} />
                <Route path="/asoebi/track-orders" element={<AsoebiTracker type="tracker" />} />
                <Route path="/asoebi-tracker" element={<AsoebiTracker />} />
                <Route path="/asoebi-tracker/create-questionnaire" element={<CreateQuestionaire />} />
                <Route path="/asoebi-tracker/complete-questionnaire" element={<CompleteGuestQuestionaire />} />
                <Route path="/asoebi-tracker/questionnaire-completed" element={<QuestionnaireCompleted />} />
                <Route path="/asoebi-tracker/questionnaire-answered" element={<QuestionnaireAnswered />} />
                <Route path="/asoebi-tracker/:id/:id" element={<Questionnaire />} />

                {/* asoebi planner auth */}
                <Route path="/asoebi/login" element={<AsoebiLogin />} />
                <Route path="/asoebi/admin-login" element={<AsoebiAdminLogin />} />

                {/* asoebi planner dashboard */}
                <Route path="/asoebi/dashboard" element={<UserDashboard />} />

                {/* asoebi admin dashboard */}
                <Route path="/admin/asoebi/login" element={<DashboardAuth />} />
                <Route path="/admin/asoebi/dashboard/questionnaires" element={<AsoebiAdminQuestionnaires />} />
                <Route path="/admin/asoebi/dashboard/payments" element={<AsoebiAdminPayments />} />
                <Route path="/admin/asoebi/dashboard/admin" element={<AsoebiAdminList />} />

                {/* protected routes */}
                <Route element={<GuardedRoute isRouteAccessible={authGuard} redirectRoute="/login" />}>
                    <Route path="/dashboard" element={<MyEvents />} />
                    <Route path="/dashboard/budget-tracker" element={<BudgetTrackerPage />} />
                    <Route path="/dashboard/checklist" element={<Checklist />} />
                    <Route path="/dashboard/edit-profile" element={<EditProfile />} />
                    <Route path="/dashboard/my-events" element={<Profile />} />
                    <Route path="/dashboard/events" element={<UserProfile />} />
                    <Route path="/dashboard/audits" element={<AuditsPage />} />
                    <Route path="/dashboard/pricing" element={<PricingPage />} />
                </Route>
            </Routes>
        </>
    );
}

export default App;
