import type { MutableRefObject, ReactText } from "react";
import { toast } from "react-toastify";

export default function useToast() {
    const loadingToast = (toastId: MutableRefObject<ReactText>) => {
        toastId.current = toast("Processing ...", {
            autoClose: false,
        });
    };
    const updateToast = (
        toastId: MutableRefObject<ReactText>,
        toastType?: "info" | "success" | "warning" | "error" | "default",
        message?: string,
    ) => {
        const autoClose = toastType === "error" ? 3000 : 1000;
        return toast.update(toastId.current, {
            type: toastType,
            updateId: toastId.current,
            autoClose,
            render: message,
        });
    };

    return {
        loadingToast,
        updateToast,
    };
}
