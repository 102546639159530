import React, {ReactNode, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import type { MouseEvent, PropsWithChildren } from "react";

import ArrowUp from "@/assets/icon/ArrowUp";
import ArrowDown from "@/assets/icon/ArrowDown";
import "@/styles/dropdown.scss";

interface Props {
	title: string;
	options?: Array<{
		text: string;
		method?: () => void;
		link?: string;
		target?: "_self" | "_blank";
	}>;
	variant?: string;
	className?: string;
	arrow_fill?: string;
	id: string;
	children?: ReactNode
}

interface CustomToggleProps {
    onClick: (e: MouseEvent<HTMLElement>) => void;
    arrow_fill?: string;
    btnClassName?: string;
}

const CustomToggle = React.forwardRef(
    (
        {
            children,
            onClick,
            arrow_fill = "#545565",
        }: PropsWithChildren<CustomToggleProps>,
        ref: React.ForwardedRef<HTMLButtonElement>,
    ) => {
        const [dropdown, setDropdown] = useState(false);

        function dropdownHandler(e: MouseEvent<HTMLElement>) {
            setDropdown((prev) => !prev);
            onClick(e);
        }

        const className = dropdown ? "active": "not_active";

        return (
            <button ref={ref} onClick={dropdownHandler} className={`btn p-0 dropdown_toggle ${className}`}>
                {children}
                <span className="ml-2">
                    {dropdown ? (
                        <ArrowUp fill={arrow_fill} />
                    ) : (
                        <ArrowDown fill={arrow_fill} />
                    )}
                </span>
            </button>
        );
    },
);

/**
 * Dropdown: Displays hidden list when clicked
 * @param title
 * @param options
 * @param variant
 * @param className
 * @returns
 */

export default function SiteDropdown({
    title,
    options,
    variant,
    arrow_fill,
    className,
    children,
    id,
}: Props) {
    return (
        <Dropdown id={id} className={className}>
            <Dropdown.Toggle variant={variant} as={CustomToggle} arrow_fill={arrow_fill} id={`dropdown-${title}`}>
                {title}
            </Dropdown.Toggle>

            <Dropdown.Menu title={title} aria-label={`${title} dropdown`}>
                {options ? options.map((dropdownItem) => {
                    return dropdownItem?.link ? (
                        <Dropdown.Item key={dropdownItem.text} target={dropdownItem.target} href={dropdownItem.link}>
                            {dropdownItem.text}
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item key={dropdownItem.text} onClick={dropdownItem.method}>
                            {dropdownItem.text}
                        </Dropdown.Item>
                    );
                }): children}
            </Dropdown.Menu>
        </Dropdown>
    );
}
