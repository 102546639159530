/* eslint-disable max-len */
import type { AxiosResponse } from "axios";
import {
    GetBudgetResponseType,
    GetUserChecklist,
    GetUserResponse,
    RequestErrorType,
    RequestType,
    AsoebiOrderType,
    auditType,
    bankListType,
    eventType,
    eventWeatherArchiveType,
    resolveBankDetailsType,
    userType,
    AsoebiOrderFormType,
} from "@/types";

import axios, { getUID } from "@/axios/config";
import { PLANADAY_GHOST_CMS_ID } from "@/utils/constant";
import { AsoebiAdminType } from "@/data/types";

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export async function getUserDetails(): Promise<GetUserResponse> {
    const userId = getUID();
    try {
        const user_details = await axios.get(`${BASE_URL}/api/v1/user/${userId}`);
        return user_details.data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getUserChecklist(eventId: string): Promise<GetUserChecklist> {
    try {
        const UID = getUID();
        const data = await axios.get(`${BASE_URL}/api/v1/user/checklist/${UID}?event_id=${eventId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getBudget(eventId: string): Promise<GetBudgetResponseType> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/category/${eventId}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getCeremonies(eventId: string) {
    try {
        const data: AxiosResponse<{ result: eventType["ceremonies"] }> = await axios.get(`${BASE_URL}/api/v1/event/${eventId}/ceremonies`);
        return data?.data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getEventTags() {
    try {
        const data: AxiosResponse<{
			tags: Array<{ name: string; url: string }>;
		}> = await axios.get(`https://planaday-blog.ghost.io/ghost/api/content/tags/?key=${PLANADAY_GHOST_CMS_ID}`);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getEventLocationCoordinates(location: string | null) {
    try {
        const locationQuery = location ? `?location=${location}` : "";
        const data = await axios.get(`${BASE_URL}/api/v1/get-event-coordinates${locationQuery}`);
        return data.data;
    } catch (error) {
        throw new Error("Unable to fetch location co-ordinates");
    }
}

// date format 2023-08-10
export async function getEventWeatherForecast(
    coordinates: {
		latitude: number;
		longitude: number;
	},
    date: string | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
    try {
        const { latitude, longitude } = coordinates;

        const latitudeQuery = latitude ? `?latitude=${latitude}&date=${date}` : "";
        const longitudeQuery = longitude ? `&longitude=${longitude}` : "";
        const data = await axios.get(`${BASE_URL}/api/v1/get-event-weather-forecast${latitudeQuery}${longitudeQuery}`);
        return data?.data;
    } catch (error) {
        throw new Error("Unable to fetch event weather forecast");
    }
}

// date format 2023-08-10
export async function getEventWeatherArchiveData(
    coordinates: {
		latitude: number;
		longitude: number;
	},
    date: string | null,
): Promise<{ result: eventWeatherArchiveType }> {
    try {
        const { latitude, longitude } = coordinates;
        const latitudeQuery = latitude ? `?latitude=${latitude}&date=${date}` : "";
        const longitudeQuery = longitude ? `&longitude=${longitude}` : "";
        const data = await axios.get(`${BASE_URL}/api/v1/get-event-weather-forecast-archive${latitudeQuery}${longitudeQuery}`);
        return data.data;
    } catch (error) {
        throw new Error("Unable to fetch event weather forecast");
    }
}

export async function getQuotes() {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/get-quotes`);
        return data;
    } catch (error) {
        throw new Error("Unable to fetch quotes");
    }
}

export async function getAudits(eventId: string): Promise<AxiosResponse<{ result: auditType }>> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/audits/${eventId}`);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getEvents(): Promise<{ result: eventType[] }> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/events`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getOnboardingDetails(): Promise<{ result: userType["onboarding"] }> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/user-onboard-details`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getAsoebiQuesionnaireDetails(id: string): Promise<{ result: AsoebiOrderFormType }> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/asoebi-questionnaire/${id}`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getListOfBanks(): Promise<{ result: bankListType }> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/fetch-banks`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function resolveBankDetails(accountNumber: string, bankCode: string): Promise<{ message: string; result: resolveBankDetailsType }> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/resolve-bank-details?account_number=${accountNumber}&bank_code=${bankCode}`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getAsoebiAdminOverviewDetails(): Promise<{
	message: string;
	result: { no_of_planners: number; no_of_guest: number; total_amount_paid_by_guest: number; questionnaires: AsoebiOrderType[] };
}> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/asoebi-admin-overview`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getAsoebiPlannerQuestionnaires(
    emailAddress: string,
): Promise<{ message: string; result: { planner_questionnaires: AsoebiOrderType[] } }> {
    try {
        const data = await axios.get(`${BASE_URL}/api/v1/planner-questionnaires?email=${emailAddress}`);
        return data.data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function getAsoebiOrders(vendor_slug: string): Promise<AxiosResponse<{ message: string; result: AsoebiOrderType[]; type: RequestType }>> {
    try {
        return await axios.get(`${BASE_URL}/api/v1/vendor-asoebi-orders/${vendor_slug}`);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getAsoebiVendorDetails(
    vendor_email: string,
): Promise<AxiosResponse<{ message: string; result: AsoebiAdminType| null; type: RequestType }>> {
    try {
        return await axios.get(`${BASE_URL}/api/v1/vendor-asoebi-admin-details/${vendor_email}`);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function getAsoebiOrderDetails(
    orderform_id: string,
): Promise<AxiosResponse<{ message: string; result: AsoebiOrderFormType | null; type: RequestType }>> {
    try {
        return await axios.get(`${BASE_URL}/api/v1/asoebi-orderform/${orderform_id}`);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}
