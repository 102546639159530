import { cloneDeep, groupBy, isNumber } from "lodash";
import React, { Suspense, useEffect, useState, useCallback } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Slider from "rc-slider";
import { v4 as uuidv4 } from "uuid";

import { formatCurrency, errorMessage, successMessage, validateEmail } from "../helper";
import {
    Action,
    Label,
    ModelResult,
    ModelResultItemBreakdown,
    ModelResultSummary,
    ScreenCategory,
    Location,
    breakdownType,
    InputData,
    BudgetCategoriesType,
} from "../data/types";
import { eventTrack, socialMediaReport } from "../analytics";
import { addBudgetEstimate, exportBudget } from "@/axios/post-request";
import DiscussionEmbedApplication from "./DiscussionEmbedApplication";
import { categoryLabels, links, socials } from "@/data/calculator_answer_links";
import { useDownloadBudgetSubcribe } from "@/hooks/useDownloadBudgetSubcribe";
import arrow from "../assets/img/arrow.svg";
import check from "../assets/img/check2.svg";
import group from "../assets/img/group.svg";
import location from "../assets/img/location.svg";
import naira from "../assets/img/naira.svg";
import pencil from "../assets/img/pencil.svg";
import arrowDown from "../assets/img/arrow_down.svg";
import chat from "../assets/img/chat.svg";
import checkboxWhite from "../assets/img/checkbox_white.svg";
import formatOnboardingBudgetEstimate, { onboardingBudgetCategories } from "@/utils/formatOnboardingBudgetEstimate";
import { budgetType, onboardingFormDetailsType } from "@/types";
import { useEvent } from "@/hooks/useEvent";
import { getUID } from "@/axios/config";
import { updateOnboardingDetails } from "@/axios/put-request";
import { budgetCategories } from "@/utils/constant";
import "../styles/budget.scss";
import "../styles/modal.scss";
import "rc-slider/assets/index.css";

// lazy import modals
const DownloadModal = React.lazy(() => import("./modals/DownloadModal"));
const CalculatorVendorAssistanceModal = React.lazy(() => import("./modals/CalculatorVendorAssistanceModal"));

type FormProps = {
    onClick: () => void;
    modelResult: ModelResult;
    form?: {
        user: string;
        budget: number;
        hasBudget: boolean;
        days: number;
    };
    data?: InputData[];
    eventCeremonies?: onboardingFormDetailsType["ceremonies"]
};

type modalType = null | "download_modal" | "vendor_assistance_modal";
type categoryType = keyof typeof BudgetCategoriesType;

export default function CalculatorAnswer({ onClick, modelResult, form, data, eventCeremonies }: FormProps) {
    const [category, setCategory] = useState<categoryType>("standard");
    const { selectedEventId } = useEvent();
    const [showBreakdown, setShowBreakdown] = useState<boolean>(false);
    const [modal, setModal] = useState<modalType>(null);
    const navigate = useNavigate();
    const [categorisedBreakdown, setCategorisedBreakdown] = useState(modify_breakdown(modelResult));
    const summaryValues: number[] = Object.values(modelResult.summary).filter((s) => isNumber(s));
    const summaryMinValue = Math.min(...summaryValues);
    const summaryMaxValue = Math.max(...summaryValues);
    const [providedBudget, setProvidedBudget] = useState(summaryMinValue || 0);

    useEffect(() => {
        if (categorisedBreakdown) {
            setShowBreakdown(true);
            if (providedBudget <= modelResult.summary.standard_total) {
                setCategory("standard");
            } else if (providedBudget > modelResult.summary.standard_total && providedBudget <= modelResult.summary.mid_luxury_total) {
                setCategory("mid_luxury");
            } else if (providedBudget > modelResult.summary.mid_luxury_total) {
                setCategory("luxurious");
            }
        }
    }, [categorisedBreakdown, providedBudget, modelResult.summary]);

    useEffect(() => {
        if (form && form.budget) {
            const categoryState =
                form.budget < budgetCategories.standard.max ?
                    BudgetCategoriesType.standard:
                    form.budget > budgetCategories.mid_luxury.min && form.budget <= budgetCategories.mid_luxury.max ?
                        BudgetCategoriesType.mid_luxury :
                        form.budget > budgetCategories.luxurious.min ?
                            BudgetCategoriesType.luxurious :
                            BudgetCategoriesType.standard;

            setCategory(categoryState);
        }
    }, [form]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const categoryBreakdown: Array<{
        item: string;
        item_code: string;
        category: string;
        cost_standard: number;
        cost_mid_luxury: number;
        cost_luxurious: number;
        checked: boolean;
    }> = [];

    const categorisedBreakdownValues = Object.values(categorisedBreakdown);

    categorisedBreakdownValues.map((item) => {
        item.map((categoryItem) => {
            if (categoryItem.checked) {
                categoryBreakdown.push(categoryItem);
            }
        });
    });

    const sliderMarks = {
        [modelResult.summary.standard_total]: {
            style: {},
            label: "On a budget",
        },
        [modelResult.summary.mid_luxury_total]: {
            style: {},
            label: "Can splurge",
        },
        [modelResult.summary.luxurious_total]: {
            style: {},
            label: "Luxury wedding",
        },
    };

    function modify_breakdown(modelResult: ModelResult) {
        const model_result = groupBy(modelResult.breakdown, "category");
        let item: string;
        // eslint-disable-next-line guard-for-in
        for (item in model_result) {
            model_result[item].forEach((item) => {
                item.checked = true;
            });
        }
        return model_result;
    }

    const { commSubscribed } = useDownloadBudgetSubcribe();

    function updateCheckStatus(category: string, itemCode: string) {
        const breakdownCopy = cloneDeep(categorisedBreakdown);
        const item = breakdownCopy[category].find((i) => i.item_code === itemCode);
        if (item) {
            item.checked = !item.checked;
            setCategorisedBreakdown(breakdownCopy);
        }
    }

    const calculateLineItemTotal = useCallback(
        (option) => {
            return Math.round(option[`cost_${category}`]);
        },
        [category],
    );

    function calulateBreakDownPrice(item: ModelResultItemBreakdown) {
        return calculateLineItemTotal(item);
    }

    let sum_of_guests = 0;

    function sortBudget() {
        const breakdown: breakdownType[] = [];
        data?.map((entry: InputData) => {
            sum_of_guests += entry.no_of_guests;
        });

        // eslint-disable-next-line guard-for-in
        for (const item_category in categorisedBreakdown) {
            categorisedBreakdown[item_category].forEach((item: ModelResultItemBreakdown) => {
                breakdown.push({
                    item_code: item.item_code,
                    price: calulateBreakDownPrice(item),
                    item: item.item,
                    item_category: item.category,
                    checked: item.checked,
                });
            });
        }
        breakdown.push({
            sum_of_guests,
            total_cost: calculateTotalBudget(),
            duration: form?.days,
            budget: form?.hasBudget ? form.budget : "No Budget",
        });
        return breakdown;
    }

    async function sendBudget(email: string) {
        const valid = validateEmail(email);
        if (!valid) {
            errorMessage("Please enter a valid email address");
            return;
        }
        const budget_breakdown = sortBudget();
        let locations = "";
        data?.forEach((item) => {
            locations += `${item.location},`;
        });
        const res = await exportBudget({
            guests: sum_of_guests,
            user: form?.user as string,
            number_of_days: form?.days as number,
            locations,
            email_address: email,
            export_type: "PDF",
            budget_breakdown,
            budget_id: uuidv4(),
            user_comms_subscribed: commSubscribed,
            help_find_vendor: false,
        });

        if (res.status === 200) {
            if (commSubscribed === true) {
                eventTrack(ScreenCategory.CALCULATOR_ANSWER_SCREEN, Action.TOTAL_SUBSCRIBED_TO_UPDATES_COUNT, Label.BUTTON);
            } else {
                eventTrack(ScreenCategory.CALCULATOR_ANSWER_SCREEN, Action.TOTAL_NOT_SUBSCRIBED_TO_UPDATES_COUNT, Label.BUTTON);
            }
            successMessage(res.data.message);
        } else {
            errorMessage(res.data.message || "An error occurred, try again later!");
        }
        return res;
    }

    const openLink = (linkUrl: string) => {
        const newWindow = window.open(linkUrl, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    function displayLocation(data: InputData[]) {
        if (Array.isArray(data) === true) {
            data.map((entry) => {
                const location = entry.location;
                if (location === "Other") {
                    data[0].location = location;
                }
            });
            if (data.length > 1) {
                return "Multiple";
            } else {
                return addLag(data[0].location);
            }
        }
    }
    const calculateTotalBudget = useCallback(() => {
        let total = 0;
        const itemsGroups = Object.values(categorisedBreakdown);
        itemsGroups.forEach((items) => {
            items.forEach((item) => {
                if (item.checked === true) {
                    total += calculateLineItemTotal(item);
                }
            });
        });
        return total;
    }, [calculateLineItemTotal, categorisedBreakdown]);

    const addLag = (location: string) => {
        if (location === Location.ISLAND || location === Location.MAINLAND) {
            return `Lagos-${location}`;
        }
        return location;
    };

    function closeModalHandler() {
        setModal(null);
    }

    const showDownloadModal = modal === "download_modal";
    const showVendorassistanceModal = modal === "vendor_assistance_modal";

    const _totalBudget = calculateTotalBudget();
    const totalBudget = formatCurrency(_totalBudget.toString());
    const _summaryMinValue = Math.trunc(summaryMinValue);
    const summaryMinValueInCurrency = formatCurrency(_summaryMinValue.toString());
    const _summaryMaxValue = Math.trunc(summaryMaxValue);
    const summaryMaxValueInCurrency = formatCurrency(_summaryMaxValue.toString());
    const modelTotal = `${category}_total`;
    const modelResultSummaryValue = modelResult.summary[modelTotal as keyof ModelResultSummary];
    const budgetAmount = formatCurrency(modelResultSummaryValue.toString());
    const budgetCurrency = form ? formatCurrency(form?.budget.toString()) : "0";

    const onboardingBudgetCategoriesEstimate = formatOnboardingBudgetEstimate(
        {
            breakdown: categoryBreakdown,
            budget_category: category,
        },
        "all",
    );
    const onboardingBudget: unknown = onboardingBudgetCategories(onboardingBudgetCategoriesEstimate);
    const _onboardingBudget = onboardingBudget as budgetType;

    async function proceedToOnboarding() {
        const userId = getUID();
        if (userId) {
            await updateOnboardingDetails(userId,
                { proceed_from_calculator_to_onboarding: true, completed: false });
        }
        await addBudgetEstimate(
            {
                ..._onboardingBudget,
            },
            selectedEventId,
        );
        if (userId) {
            await updateOnboardingDetails(userId,
                { proceed_from_calculator_to_onboarding: true, completed: false, onboarding_stage: 2});
        }
        navigate("/onboarding/invite-event-collaborator");
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                {modal === "download_modal" &&
                <DownloadModal
                    sendBudget={sendBudget}
                    show={showDownloadModal}
                    onHide={closeModalHandler} />}
                {modal === "vendor_assistance_modal" &&
                <CalculatorVendorAssistanceModal
                    show={showVendorassistanceModal}
                    onHide={closeModalHandler}
                />}
            </Suspense>
            <section className="section answers">
                <ToastContainer position="top-center" autoClose={2000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} />
                <div className="budget_calculator_banner bg-white py-4 px-lg-5 px-3 pb-2 mb-5 rounded">
                    <h5 className="mb-0 small font-weight-bold text-center">
					Make Event Planning a Breeze, with our new {" "}
                        <a target="_blank" href="https://planaday.events?utm_source=budget+calculator+&utm_medium=redirect">Budget tracker</a>,
                        {" "}<a target="_blank" href="https://planaday.events?utm_source=budget+calculator+&utm_medium=redirect">Checklist</a> and
                        {" "} <a target="_blank"
                            href="https://tools.planaday.events/asoebi?utm_source=budget+calculator+&utm_medium=redirect">Aso Ebi Tracker</a> tools
                    </h5>
                </div>
                <div className="page__content">
                    <div className="card">
                        <div className="answers--heading">
                            <h2>
                                Your wedding cost estimate is: <span className="cost">{totalBudget}</span>
                            </h2>
                            <p>Our cost calculator uses market prices to estimate the cost of your dream wedding</p>
                        </div>
                        <div className="pink-bg mb-4">
                            <div className="pink-bg__body br">
                                <img src={naira} alt="naira" className="pink-bg__icon" />
                                <div className="pink-bg__text">
                                    <span>BUDGET</span>
                                    {form?.hasBudget && <p>{budgetCurrency}</p>}
                                    {!form?.hasBudget && <p>No budget</p>}
                                </div>
                            </div>
                            <div className="pink-bg__body br">
                                <img src={group} alt="group" className="pink-bg__icon" />
                                <div className="pink-bg__text">
                                    <span>GUEST</span>
                                    <p>{data?.reduce((sum: number, { no_of_guests }: { no_of_guests: number }) => sum + no_of_guests, 0)}</p>
                                </div>
                            </div>
                            <div className="pink-bg__body">
                                <img src={location} alt="group" className="pink-bg__icon" />
                                <div className="pink-bg__text">
                                    <span>LOCATION</span>
                                    {data && <p>{displayLocation(data)}</p>}
                                </div>
                            </div>
                            <button
                                className="btn edit-btn w-full h-full"
                                onClick={onClick}>
                                <img src={pencil} alt="Pencil" />
                            </button>
                        </div>
                        {!form?.hasBudget ? (
                            <div className="answers--none">
                                <p>
                                    Our market guru tells us that you could spend between <span>{summaryMinValueInCurrency}</span> and{" "}
                                    <span>{summaryMaxValueInCurrency}</span>!
                                    <br />
                                    <span>Select your ideal budget category from this range for a better cost estimate</span>
                                </p>
                                <div className="slider_wrap">
                                    <div style={{ width: "90%" }}>
                                        <Slider
                                            className="custom-range"
                                            min={summaryMinValue}
                                            max={summaryMaxValue}
                                            marks={sliderMarks}
                                            step={null}
                                            onChange={(value) => setProvidedBudget(Number(value as number))}
                                            defaultValue={summaryMinValue}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="answers--none">
                                <p>
                                    Our market guru tells us that you could spend between <span>{summaryMinValueInCurrency}</span> and{" "}
                                    <span>{summaryMaxValueInCurrency}</span>!
                                    <br />
                                    Your budget is closer to <span>{budgetAmount}</span>. We will show you the breakdown for that amount.
                                </p>
                            </div>
                        )}
                        <h3>Budget Breakdown</h3>
                        <p className="mb-5 breakdown font-500">
                            We’ve assumed that you’ll need all these items for your wedding. Uncheck what is not needed and we’ll adjust our estimate!
                        </p>
                        <div className="budget__button-group d-flex  mb-5  flex-lg-row flex-column justify-content-end align-items-center gap-4">
                            {eventCeremonies ? (
                                <Button
                                    variant=""
                                    // eslint-disable-next-line max-len
                                    className="d-flex justify-content-center proceed_to_create_event  px-0 px-md-4 mx-auto text-white align-items-center font-600 py-3  bg-site_base"
                                    onClick={proceedToOnboarding}>
                                    Proceed to create event
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        variant=""
                                        className="d-flex text-white align-items-center font-600 py-3  bg-site_base"
                                        onClick={() => setModal("download_modal")}>
                                        Download budget
                                        <img src={arrowDown} alt="download budget icon" className="ml-3" />
                                    </Button>
                                    <Button
                                        variant=""
                                        className="d-flex text-white align-items-center font-600 py-3 bg-site_base"
                                        onClick={() => setModal("vendor_assistance_modal")}>
                                        Vendor assistance
                                        <img src={chat} alt="Vendor assistance icon" className="ml-3" />
                                    </Button>
                                </>
                            )}
                        </div>
                        {showBreakdown && (
                            <>
                                {Object.entries(categoryLabels).map(([category, { title }], idx) => {
                                    return (
                                        <div className="mb-3 card__category" key={`category-${category}-${idx}`}>
                                            <div className="card__category--title">
                                                <h3 className="budget_category">{title}</h3>
                                            </div>
                                            {categorisedBreakdown[category].map((item, index) => {
                                                return (
                                                    <div
                                                        className="d-flex justify-content-between"
                                                        key={`item-${category}-${index}-${item.item_code}`}>
                                                        <div className="custom-check">
                                                            {item.checked ? (
                                                                <img
                                                                    className="checkbox"
                                                                    src={check}
                                                                    alt="checkbox"
                                                                    onClick={() => {
                                                                        updateCheckStatus(category, item.item_code);
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className="checkbox"
                                                                    src={checkboxWhite}
                                                                    alt="checkbox"
                                                                    onClick={() => {
                                                                        updateCheckStatus(category, item.item_code);
                                                                    }}
                                                                />
                                                            )}
                                                            <p className={item.checked ? "" : "underline"}>
                                                                {item.item_code === "OTHER-MIS" ? (
                                                                    <>
                                                                        {" "}
                                                                        {item.item}
                                                                        <OverlayTrigger
                                                                            placement="right"
                                                                            overlay={
                                                                                <Tooltip id="budget">
                                                                                    Surprises happen! We've estimated this amount to keep you covered
                                                                                </Tooltip>
                                                                            }>
                                                                            <span className="question-misc">
                                                                                <span>?</span>
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </>
                                                                ) : (
                                                                    item.item
                                                                )}
                                                            </p>
                                                        </div>
                                                        <p className={item.checked ? "" : "underline"}>
                                                            <span>{formatCurrency(calculateLineItemTotal(item), "₦")}</span>
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </>
                        )}

                        <div className="mini-cards">
                            {links.map((option, index) => {
                                return (
                                    <div className="mini-card" key={index} onClick={() => openLink(option.link)}>
                                        <p>{option.text}</p>
                                        <p>{option.subtext}</p>

                                        <div className="arrow">
                                            <img src={arrow} alt="arrow" />
                                        </div>
                                        <img src={option.icon} alt="icon" className="icon" />
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="share">
                        {socials.map(
                            (
                                val: {
                                    link: string;
                                    icon: string;
                                    name: string;
                                },
                                index: number,
                            ) => {
                                return (
                                    <a className="btn" key={index} href={val.link} target="_blank" onClick={() => socialMediaReport(val.name)}>
                                        <img src={val.icon} alt="Social share" />
                                        <span>Share</span>
                                    </a>
                                );
                            },
                        )}
                    </div>
                </div>
            </section>
            <DiscussionEmbedApplication />
        </>
    );
}
