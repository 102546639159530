import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import type { ChangeEvent } from "react";
import type { MultiValue } from "react-select";

import SiteModal from "@/components/modals/SiteModal";
import Input from "@/components/form/Input";
import Button from "@/components/Button";
import event_modal_content from "@/json/form/budget_tracker_modal.json";
import { createEvent } from "@/axios/post-request";
import CeremonyPicker from "@/components/form/CeremonyPicker";
import PricingTable from "@/components/PricingTable";
import { CollaboratorPaymentPlan, CollaboratorPlanStatus, EventPlanStatus, EventPlanType, PaymentType, eventType, pricingPlanType } from "@/types";
import PricingPayment from "@/components/PricingPayment";
import generateRandomColor from "@/utils/generateRandomColor";
import useUser from "@/hooks/useUser";
import useAudit from "@/hooks/useAudit";
import useMyEvents from "@/hooks/useMyEvents";

interface Props {
    show: string | null;
    onHide: () => void;
    canAddNewEvent: boolean;
}

type eventStateType = {
    event_name: string;
    location: string;
    no_of_guests: number;
    event_date: Date | string;
    ceremonies: MultiValue<{ label: string; value: string }>;
};

export default function AddEventModal({ show, onHide, canAddNewEvent }: Props) {
    const { event: event_ceremony } = event_modal_content;
    const { data, selectedEventId } = useUser();
    const {updateAuditHandler} = useAudit();
    const {activeEvent} = useMyEvents();
    const [selectPricingPlan, setPricingPlan] = useState<pricingPlanType>("");
    const [event, setEvent] = useState<eventStateType>({
        event_name: "",
        location: "",
        no_of_guests: 0,
        event_date: "",
        ceremonies: [],
    });
    const queryClient = useQueryClient();

    function addNewEventHandler() {
        if (!event.event_name) {
            return toast.error("Event name is required");
        }
        mutate();
    }

    function updateSelectedCeremonies(_ceremonies: unknown) {
        const ceremonies = _ceremonies as MultiValue<{
            label: string;
            value: string;
        }>;
        setEvent({
            ...event,
            ceremonies,
        });
    }

    function createEventHandler() {
        const ceremonies: eventType["ceremonies"] = [];
        event.ceremonies.map((ceremony) => {
            ceremonies.push({
                id: uuidv4(),
                date: null,
                location: null,
                name: ceremony.label,
                show_suggestion: true,
                no_of_guests: null,
                checklist: []
            });
        });
        const user_id = data?.result?.id as string;
        const event_bg = generateRandomColor();
        const today = new Date();
        return createEvent({
            ...event,
            id: uuidv4(),
            ceremonies: ceremonies,
            collaborators: [
                {
                    id: data?.result?.uid as string,
                    email: data?.result?.email as string,
                    role: "Event Owner",
                    plan_duration: null,
                    send_plan_expiration_email: false,
                    event_ids: [selectedEventId],
                    payment_plan: CollaboratorPaymentPlan.FREE,
                    plan_status: CollaboratorPlanStatus.ACTIVE,
                    start_plan_date: new Date().toISOString(),
                    end_plan_date: null,
                },
            ],
            event_bg,
            plan: activeEvent ? activeEvent[0].plan: EventPlanType.FREE,
            plan_duration_month: activeEvent ? activeEvent[0].plan_duration_month: null,
            plan_payment_type: activeEvent? activeEvent[0].plan_payment_type: null,
            plan_status: EventPlanStatus.ACTIVE,
            plan_amount: activeEvent ? activeEvent[0].plan_amount: null,
            event_owner: {
                event_id: selectedEventId,
                email: data?.result?.email as string,
                role: data?.result?.role as string,
                id: data?.result?.id as string,
            },
            expired_collaborators_ids: [],
            collaborator_per_events: 2,
            max_events: 3,
            collaborator_ids: [user_id],
            createdAt: today.toISOString(),
        });
    }

    const { mutate, isLoading } = useMutation({
        mutationKey: ["addCeremony"],
        mutationFn: () => createEventHandler(),
        onSuccess: () => {
            toast.success("Event added");
            updateAuditHandler("general", "add_event", event.event_name, null);
            queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
            queryClient.resetQueries({ queryKey: [`fetch_ceremonies_${selectedEventId}`] });
            onHide();
        },
        onError: (error) => {
            const _error = error || "error adding ceremony";
            toast.error(_error);
        },
    });

    const showModal = show === "add_new_event_modal" ? true : false;

    function inputChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        const inputValue = e.target.value;
        setEvent({
            ...event,
            [e.target.name]: inputValue,
        });
    }

    function choosePricingPlan(plan: pricingPlanType) {
        setPricingPlan(plan);
    }

    function resetPricingPlan() {
        setPricingPlan("");
    }

    const disableButton = event.ceremonies.length === 0 || event.ceremonies.length > 7 ? true : false;

    const modalClassname = selectPricingPlan ? "" : canAddNewEvent ? "" : "bigger";

    const modalTitle = canAddNewEvent ? "Add Event" : selectPricingPlan ? `${selectPricingPlan} Pricing` : "Pricing Plan";

    return (
        <SiteModal title={modalTitle} show={showModal} onHide={onHide} className={modalClassname}>
            <>
                {canAddNewEvent ? (
                    <section className="add_new_ceremony">
                        <p>Create a new event.</p>
                        <form className="mt-4 mb-3 modal_form">
                            {event_ceremony.columns.map((input) => {
                                const inputName = input.name as keyof eventStateType;
                                const name = event[inputName] as string;
                                return <Input input={input} key={input.name} value={name} onChange={inputChangeHandler} />;
                            })}
                            <CeremonyPicker
                                className="col-12 px-0 mt-1"
                                ceremonies={event.ceremonies}
                                value={event.ceremonies}
                                onChange={updateSelectedCeremonies}
                            />

                            <div className="d-flex justify-content-end">
                                <Button
                                    text="Save"
                                    className="bg_red text-white mt-2"
                                    isLoading={isLoading}
                                    onClick={addNewEventHandler}
                                    disabled={disableButton}
                                />
                            </div>
                        </form>
                    </section>
                ) : (
                    <PricingTable type="event" selectPricingPlan={selectPricingPlan} choosePricingPlan={choosePricingPlan}>
                        <PricingPayment
                            title={selectPricingPlan}
                            onCloseModal={onHide}
                            paymentType={PaymentType.EVENT}
                            resetPricingPlan={resetPricingPlan} />
                    </PricingTable>
                )}
            </>
        </SiteModal>
    );
}
