import { Helmet } from "react-helmet";

export default function InitializeHotjar() {
    return (
        <Helmet>
            <script>
                {`
			(function(h,o,t,j,a,r){
				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
				h._hjSettings={hjid:3860222,hjsv:6};
				a=o.getElementsByTagName('head')[0];
				r=o.createElement('script');r.async=1;
				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
				a.appendChild(r);
			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}
            </script>
        </Helmet>
    );
}

interface Props {
	userEmail?: string;
}

export function HotjarIdentifyUser({ userEmail }: Props) {
    return (
        <Helmet>
            {userEmail && (
                <script>
                    {`
                    window.hj('identify', ${userEmail});
                `}
                </script>
            )}
        </Helmet>
    );
}
