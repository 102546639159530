import axios from "axios";

import type { PostResponseWithoutDataType } from "@/data/types";
import type { Asoebi_order_status, RequestErrorType, costItemType, todoType, userType } from "@/types";

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

type inviteUserPartnerType = {
    collaborators: Array<{ email: string; role: string; event_ids: string[] }>;
    inviteeEmail: string;
    inviteeRole: string;
    inviteeName: string;
    eventId: string;
};

export async function inviteUserPartner(
    id: string,
    { eventId, collaborators, inviteeEmail, inviteeName, inviteeRole }: inviteUserPartnerType,
): Promise<PostResponseWithoutDataType> {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/user/invite-collaborator/${id}`, {
            collaborators,
            eventId,
            inviteeName,
            inviteeEmail,
            inviteeRole,
        });
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response?.data?.message);
    }
}

export async function updateUserChecklist(
    checklist: {
        id: string;
        name?: string;
        due?: Date | null;
        ceremony?: { id: string; name: string };
        isDone: boolean;
        note?: string | null;
    },
    filterCeremonyId: string,
    eventId: string,
): Promise<{ data: { message: string } }> {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/event/${eventId}/checklist/update/${filterCeremonyId}`, { checklist });
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateCostItems(categoryId: string, costItem: costItemType, eventId: string): Promise<{ data: { message: string } }> {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/event/${eventId}/update-cost-item`, { cost_item: costItem, category_id: categoryId });
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function editUserProfile(body: { email: string; name: string }): Promise<{ data: { message: string } }> {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/user/edit_profile`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type updateCeremonyBodyType = {
    id?: string;
    name?: string;
    location?: string | null;
    no_of_guests?: string | number | null;
    date?: Date | null;
    checklist?: todoType | undefined;
    show_suggestion?: boolean | undefined;
};

export async function updateCeremony(ceremony: updateCeremonyBodyType, ceremonyId: string, eventId: string) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/event/${eventId}/ceremony/update/${ceremonyId}`, ceremony);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateAuditUsername(name: string, eventId: string, user_id:string) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/update-audit-username/${eventId}`, { name, user_id });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function deactiveEvent(eventId: string, expired_collaborator_email: string) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/deactivate-event/${eventId}`, { expired_collaborator_email });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type costItemDetailsType = {name:string, category_id:string};

export async function updateCostItemName(eventId: string, costItemId: string, costItemDetails:costItemDetailsType) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/event/${eventId}/update-cost-item-title/${costItemId}`, costItemDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateOnboardingDetails(userId: string, onboardingDetails:Partial<userType["onboarding"]>) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/user/${userId}/onboard`, onboardingDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateUserDetails(userId: string, userDetails: Partial<userType>) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/update-user/${userId}`, userDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateOrderStatus(orderId: string, status: keyof typeof Asoebi_order_status) {
    try {
        const data = await axios.put(`${BASE_URL}/api/v1/update-asoebi-order-status/${orderId}`, { status });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}
