import { create } from "zustand";

type modalType =
	| null
	| "vendor_assistance_modal"
	| "category_modal"
	| "payment_modal"
	| "delete_modal"
	| "add_ceremony_modal"
	| "edit_ceremony_modal"
	| "collaborator_modal"
	| "show_event_collaborator"
	| "update_asoebi_status_modal"
	| "delete_budget_category_modal";

type useModalType = {
    modal: modalType;
    setModal: (value: modalType) => void;
    modalDetails: null | { [key: string]: string | number };
    setModalDetails: (value: null | { [key: string]: string | number }) => void;
};

export const useModal = create<useModalType>((set) => ({
    modal: null,
    setModal: (value: modalType) => set(() => ({ modal: value })),
    modalDetails: null,
    setModalDetails: (value) => set(() => ({ modalDetails: value })),
}));
