/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { ChangeEvent, useEffect, useState, Suspense, useRef, Fragment } from "react";
import FuzzySearch from "fuzzy-search";
import { useNavigate, useSearchParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";

import { getAsoebiOrders, getAsoebiQuesionnaireDetails, getAsoebiVendorDetails } from "@/axios/get-request";
import AsoebiDashboardLayout from "@/layout/AsoebiDashboardLayout";
import { convertCurrencyToNumber, formatCurrency } from "@/helper";
import ArrowleftIcon from "@/assets/icon/ArrowleftIcon";
import ArrowRightIcon from "@/assets/icon/ArrowRightIcon";
import Select from "@/components/form/Select";
import Search from "@/assets/icon/Search";
import CancelIcon from "@/assets/icon/Cancel";
import Loader from "@/components/loader";
import { RequestType, type AsoebiOrderType, Asoebi_order_status } from "@/types";
import SiteDropdown from "@/components/Dropdown";
import UpdateAsoebiOrderModal from "@/components/modals/UpdateAsoebiOrderModal";
import { useModal } from "@/hooks/useModal";
import useToast from "@/hooks/useToast";
import { updateOrderStatus } from "@/axios/put-request";
import { verifyMagicLink } from "@/axios/post-request";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import DownArrow from "@/assets/icon/DownArrow";
import AseobiDashboardLoader from "@/components/loaders/AseobiDashboardLoader";
import PlusIcon from "@/assets/icon/PlusIcon";

const selectLimitDetails = {
    label: "",
    name: "events",
    options: [5, 10, 20, 30, 50],
};

const dropdownOptions = [
    { text: "Shipped", value: "SHIPPED" },
    { text: "Delivered", value: "DELIVERED" },
    { text: "Pending", value: "PENDING" },
] as Array<{ text: string; value: keyof typeof Asoebi_order_status }>;

type filterOptionsType = "All" | keyof typeof Asoebi_order_status | "Pending";
type orderStatusType = keyof typeof Asoebi_order_status | null;
const filterOptions = [
    { text: "All", value: "All" },
    { text: "Shipped", value: "SHIPPED" },
    { text: "Delivered", value: "DELIVERED" },
    { text: "Pending", value: "PENDING" },
];
const filterInput = { name: "selectStatus", label: "Filter By Status" };

export default function UserDashboard() {
    const [tokenValue, setTokenValue] = useState("");
    const [limit, setLimit] = useState(5);
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [pageCounter, setPageCounter] = useState(1);
    const { modal, setModal } = useModal();
    const [activeCollapsedTableIndex, setActiveCollapsedTableIndex] = useState<null | number>(null);
    const [searchAudit, setSearchAudit] = useState<AsoebiOrderType[]>([]);
    const [filter, setFilter] = useState<filterOptionsType>("All");
    const [selectedEventName, setSelectedEventName] = useState<string | null>(null);
    const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
    const queryClient = useQueryClient();
    const { loadingToast, updateToast } = useToast();
    const toastId = useRef("update_order_status");
    const [OrderStatus, setOrderStatus] = useState<orderStatusType>(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get("token") as string;

    const { data: verifyData, status: verifyStatus } = useQuery({
        queryKey: ["verify_magic_link"],
        queryFn: () => verifyMagicLink(tokenValue),
        onSuccess: (data) => {
            if (token && data.data.type === RequestType.Success) {
                window.localStorage.setItem(LOCAL_STORAGE_KEYS.magiclink_token, token);
            }
            if (data.data.type === RequestType.Error) {
                return navigate("/asoebi/login");
            }
        },
        onError: () => {
            return navigate("/asoebi/login");
        },
        enabled: !!tokenValue,
    });

    const { data: adminData, status: adminStatus } = useQuery({
        queryFn: () => getAsoebiVendorDetails(verifyData?.data.result.email as string),
        queryKey: ["admin_data"],
        enabled: !!verifyData?.data.result.email,
    });

    const { data, status } = useQuery({
        queryFn: () => getAsoebiOrders(verifyData?.data.result.email as string),
        queryKey: ["get_vendor_asoebi_orders"],
        enabled: !!verifyData?.data.result.email,
    });

    const { data: orderformDetails } = useQuery({
        queryFn: () => getAsoebiQuesionnaireDetails(adminData?.data.result?.questionnaire_ids[0] as string),
        queryKey: ["getAsoebiQuesionnaireDetails"],
        enabled: !!adminData?.data?.result?.questionnaire_ids[0],
    });

    useEffect(() => {
        if (token) {
            return setTokenValue(token);
        }

        const magiclinkTokenStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.magiclink_token);

        if (magiclinkTokenStorage === "null" || !magiclinkTokenStorage) {
            return navigate("/asoebi/login");
        }

        if (magiclinkTokenStorage && !token) {
            return setTokenValue(magiclinkTokenStorage);
        }
    }, [token]);

    useEffect(() => {
        if (verifyData?.data.type === RequestType.Error) {
            return navigate("/asoebi/login");
        }
    }, [verifyStatus]);
    const no_of_created_order_form = adminStatus === "success" ? adminData.data.result?.questionnaire_ids.length ?? 0 : 0;
    const no_of_answered_orders = status === "success" ? data.data.result.length : 0;
    let amount_paid = 0;
    const orders_in_dollar = [];
    if (status === "success") {
        amount_paid = data.data.result.reduce((a: any, b: any) => {
            if (!b.amount_paid?.includes("$")) {
                const paid_amount = b.amount_paid ? convertCurrencyToNumber(b.amount_paid, "₦") : 0;
                return a + paid_amount;
            } else {
                const paid_amount = b.amount_paid ? convertCurrencyToNumber(b.amount_paid, "$") : 0;
                orders_in_dollar.push(true);
                return a + paid_amount;
            }
        }, 0);
    }
    const currency = orderformDetails ? orderformDetails?.result?.asoebiOptions?.currency.includes("$") ? "$" : "₦" : "";
    const amountText = currency ? currency === "$" ? "Total Amount" : "Total amount collected" : "";
    const amountTableText = currency ? currency === "$" ? "Amount" : "Amount paid" : "";
    const pageMin = pageCounter === 1 ? 0 : limit * (pageCounter - 1);
    const pageMax = pageCounter === 1 ? limit * pageCounter : limit * pageCounter;
    const filterAuditArray =
        filter !== "All" ?
            searchAudit.filter((item) => {
                const orderStatus = item.status ? item.status : "PENDING";
                return orderStatus === filter;
            }) :
            searchAudit;
    const paginatedDetails = filterAuditArray.slice(pageMin, pageMax);
    const showingDataType = filter === "All" ? searchAudit : filterAuditArray;
    const showingAuditCounter = limit * pageCounter < showingDataType.length ? limit * pageCounter : showingDataType.length;
    const totalAudits = filterAuditArray.length;
    const disableLeftArrowBtn = (totalAudits && totalAudits <= limit) || pageCounter === 1 ? true : false;
    const leftArrowColor = disableLeftArrowBtn ? "gray" : "black";
    const disableRightArrowBtn = totalAudits && totalAudits <= limit * pageCounter ? true : false;
    const rightArrowColor = disableRightArrowBtn ? "gray" : "black";

    function onSelectEventLimit(e: ChangeEvent<HTMLSelectElement>) {
        setLimit(Number(e.target.value));
    }

    function incrementPageCounter() {
        setPageCounter((prev) => prev + 1);
    }

    function decrementPageCounter() {
        if (pageCounter > 1) {
            setPageCounter((prev) => prev - 1);
        }
    }

    function cancelSearchHandler() {
        setSearchText("");
        setIsSearching(false);
        setSearchAudit([]);
    }

    function setSearchHandler(e: ChangeEvent<HTMLInputElement>) {
        setSearchText(e.target.value);
    }

    function filterAudit(event: ChangeEvent<HTMLSelectElement>) {
        const eventValue = event.target.value as filterOptionsType;
        setFilter(eventValue);
    }

    useEffect(() => {
        if (searchText) {
            setIsSearching(true);
            const searcher = new FuzzySearch(searchAudit, ["event_name", "name", "email"]);
            const _searchText = searchText.replace(/\W+/g, "");
            const result = searcher.search(_searchText);
            setSearchAudit(result);
            if (result) {
                setIsSearching(false);
            }
        } else {
            const asoebi_details = data?.data.result ? data.data.result : [];
            setSearchAudit(asoebi_details);
        }
    }, [searchText.length, status]);

    function onCloseModal() {
        setModal(null);
    }

    function updateOrderStatusHandler(orderStatus: orderStatusType, eventName: string, orderId: string | null) {
        setOrderStatus(orderStatus);
        setSelectedEventName(eventName);
        setSelectedOrderId(orderId);
        setModal("update_asoebi_status_modal");
    }

    const showUpdateAsoebiOrderStatusModal = modal === "update_asoebi_status_modal";

    async function onUpdateStatusHandler() {
        try {
            if (selectedOrderId && OrderStatus) {
                loadingToast(toastId);
                const updateOrderRequest = await updateOrderStatus(selectedOrderId, OrderStatus);
                if (updateOrderRequest.data.type !== RequestType.Success) {
                    return updateToast(toastId, "error", updateOrderRequest.data.message || "Oops an error occured, unable to update status");
                }
                updateToast(toastId, "success", updateOrderRequest.data.message || "Order status updated successfully");
                setSelectedEventName(null);
                setSelectedOrderId(null);
                setModal(null);
                queryClient.resetQueries({ queryKey: ["get_vendor_asoebi_orders"] });
            }
        } catch (error: unknown) {
            const _error = error as { message: string };
            return updateToast(toastId, "error", _error.message || "Oops an error occured, unable to update status");
        }
    }

    function showTableDetails(tableIndex: number) {
        const tableIndexValue = tableIndex === activeCollapsedTableIndex ? null : tableIndex;
        setActiveCollapsedTableIndex(tableIndexValue);
    }

    const plannerName = adminStatus === "success" ? adminData.data.result?.name || adminData.data.result?.email : "";

    function copyToClipboardHandler(textToCopy: string, name: string) {
        navigator.clipboard.writeText(textToCopy);
        toast.success(`${name} copied!`);
    }

    const create_order_form_link = currency === "$" ? "/asoebi/track-orders" : "/asoebi-tracker";

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                {modal === "update_asoebi_status_modal" && (
                    <UpdateAsoebiOrderModal
                        show={showUpdateAsoebiOrderStatusModal}
                        orderStatus={OrderStatus}
                        eventName={selectedEventName}
                        onHide={onCloseModal}
                        onUpdateStatusHandler={onUpdateStatusHandler}
                    />
                )}
            </Suspense>

            <AsoebiDashboardLayout type="user" name={plannerName}>
                <>
                    {status === "error" ? (
                        <p>Unable to fetch admin details</p>
                    ) : status === "loading" ? (
                        <AseobiDashboardLoader />
                    ) : (
                        <div className="guest_dashboard">
                            <div className="d-flex justify-content-between align-items-center">
                                <h1 className="mb-lg-4 mt-4 mb-5 text-dark">Hello {plannerName} 👋🏼</h1>
                                <a href={create_order_form_link} target="_blank" className="btn btn-primary rounded" title="create a new orderform">
                                    <PlusIcon />
                                </a>
                            </div>
                            <section className="card_view">
                                <div className="card  bg-white">
                                    <h2>{no_of_answered_orders}</h2>
                                    <h4 className="mb-0">Asoebi Orders</h4>
                                </div>
                                <div className="card  bg-white">
                                    <h2>{no_of_created_order_form}</h2> <h4 className="mb-0">Created Asoebi Forms</h4>
                                </div>
                                {
                                    <div className="card  bg-white">
                                        <h2>{formatCurrency(amount_paid, currency)}</h2>
                                        <h4 className="mb-0">{amountText}</h4>
                                    </div>
                                }
                            </section>
                            <div className="bg-white px-4 py-3 mt-5 shadow-sm rounded">
                                <div className="d-flex flex-lg-row flex-column w-full align-items-lg-center mb-3 justify-content-lg-between">
                                    <div className="filter_by_status px-0 mb-4 mb-lg-0 col-4">
                                        <Select input={filterInput} value={filter} className="filter_audit_select w-full" onChange={filterAudit}>
                                            {filterOptions.map((item, index) => (
                                                <option key={index} value={item.value}>
                                                    {item.text}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="search">
                                        <div id="search_audits" className="p-0 search_audits position-relative search_form mr-0 mb-2 mb-lg-0">
                                            <input
                                                placeholder="Search Event"
                                                className="form-control"
                                                value={searchText}
                                                onChange={setSearchHandler}
                                            />
                                            {!isSearching && (
                                                <div className="search_cta">
                                                    {!searchText ? (
                                                        <button className="position-absolute border-0 bg-transparent" type="button">
                                                            <Search fill="#545565" />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="position-absolute border-0 bg-transparent"
                                                            type="button"
                                                            onClick={cancelSearchHandler}>
                                                            <CancelIcon />
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                            {isSearching && (
                                                <span className="position-absolute search_spinner">
                                                    <Loader mode="dark" />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {paginatedDetails.length === 0 ? (
                                    <h5 className="text-center font-weight-bold mt-5 mb-4">You don't have any order yet</h5>
                                ) : (
                                    <table className="table table-sm table-responsive">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Event name</th>
                                                <th>{amountTableText}</th>
                                                <th>Created</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedDetails?.map((order, index) => {
                                                const createdAt = format(new Date(order.created_at), "dd/MM/yyyy");
                                                const orderDeadlineDate = order?.order_deadline ?
                                                    format(new Date(order.order_deadline), "dd/MM/yyyy") :
                                                    "N/A";
                                                const amountPaid = order?.amount_paid ? order?.amount_paid : "N/A";
                                                const orderText =
                                                    order.status === Asoebi_order_status.DELIVERED ?
                                                        "Delivered" :
                                                        order.status === Asoebi_order_status.PENDING ?
                                                            "Pending" :
                                                            order.status === Asoebi_order_status.SHIPPED ?
                                                                "Shipped" :
                                                                "Pending";

                                                const tableBtnClassname = activeCollapsedTableIndex === index ? "active_table_row" : "";

                                                return (
                                                    <Fragment key={order.id}>
                                                        <tr key={order.id}>
                                                            <td className="text-truncate">
                                                                <button
                                                                    className={`btn p-0 ${tableBtnClassname}`}
                                                                    onClick={() => showTableDetails(index)}>
                                                                    {order.name}
                                                                    <DownArrow />
                                                                </button>
                                                            </td>
                                                            <td className="text-truncate">{order.email}</td>
                                                            <td className="text-truncate ">{order.event_name}</td>
                                                            <td>{amountPaid}</td>
                                                            <td>{createdAt}</td>
                                                            <td>
                                                                <SiteDropdown
                                                                    id="status_dropdown"
                                                                    title={orderText}
                                                                    variant="clear"
                                                                    arrow_fill="black"
                                                                    className="d-flex">
                                                                    {dropdownOptions.map((item) => {
                                                                        return (
                                                                            item.value !== order.status && (
                                                                                <Dropdown.Item
                                                                                    key={item.text}
                                                                                    onClick={() =>
                                                                                        updateOrderStatusHandler(
                                                                                            item.value,
                                                                                            order.event_name,
                                                                                            order.id,
                                                                                        )
                                                                                    }>
                                                                                    {item.text}
                                                                                </Dropdown.Item>
                                                                            )
                                                                        );
                                                                    })}
                                                                </SiteDropdown>
                                                            </td>
                                                        </tr>
                                                        {activeCollapsedTableIndex === index && (
                                                            <tr>
                                                                <td className="w-full min-w-full table_details" colSpan={7}>
                                                                    <div className="table_list_details">
                                                                        <div>
                                                                            <div
                                                                                className="w-fit"
                                                                                onClick={() => copyToClipboardHandler(order.name, "Name")}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip id="asoebi_tooltip">click to copy name</Tooltip>
                                                                                    }>
                                                                                    <div>
                                                                                        <strong>Name: </strong>
                                                                                        {order.name}
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="w-fit">
                                                                                <strong>Email: </strong>
                                                                                {order.email}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {order?.phone_number ? (
                                                                                <div
                                                                                    className="w-fit"
                                                                                    onClick={() =>
                                                                                        copyToClipboardHandler(order.phone_number, "Phone number")
                                                                                    }>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                            <Tooltip id="asoebi_tooltip">
                                                                                                click to copy phone number
                                                                                            </Tooltip>
                                                                                        }>
                                                                                        <div>
                                                                                            <strong>Phone number: </strong>
                                                                                            {order?.phone_number ?? "N/A"}
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <strong>Phone number: </strong>
                                                                                    {order?.phone_number ?? "N/A"}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            <div className="w-fit">
                                                                                <strong>{amountTableText}: </strong>
                                                                                {order.amount_paid}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {order?.delivery_address ? (
                                                                                <div
                                                                                    className="w-fit"
                                                                                    onClick={() =>
                                                                                    // eslint-disable-next-line max-len
                                                                                        copyToClipboardHandler(
                                                                                            order?.delivery_address as string,
                                                                                            "Address",
                                                                                        )
                                                                                    }>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                            <Tooltip id="asoebi_tooltip">
                                                                                                click to copy delivery address
                                                                                            </Tooltip>
                                                                                        }>
                                                                                        <div>
                                                                                            <strong>Delivery address: </strong>
                                                                                            {order?.delivery_address ?? "N/A"}
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <strong>Delivery address: </strong>
                                                                                    {order?.delivery_address ?? "N/A"}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            {order?.special_note ? (
                                                                                <div
                                                                                    onClick={() =>
                                                                                        copyToClipboardHandler(order.special_note, "Special note")
                                                                                    }
                                                                                    className="w-fit">
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                            <Tooltip id="asoebi_tooltip">
                                                                                                click to copy special note
                                                                                            </Tooltip>
                                                                                        }>
                                                                                        <div>
                                                                                            <strong>Special note: </strong>
                                                                                            {order?.special_note ?? "N/A"}
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <strong>Special note: </strong>
                                                                                    {order?.special_note ?? "N/A"}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="table_asoebi_view">
                                                                            <strong>Asoebi options: </strong>
                                                                            <div className="list flex flex-column">
                                                                                {order?.options?.map((item, idx) => (
                                                                                    <div key={idx} className="group_list">
                                                                                        <p className="my-0">Item: {item.option},</p>
                                                                                        <p className="my-0">Price: {currency}{item.price},</p>
                                                                                        <p className="my-0">Qty: {item.qty}</p>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {order?.questionnaire_link ? (
                                                                                <div
                                                                                    onClick={() =>
                                                                                        copyToClipboardHandler(
                                                                                            order.questionnaire_link,
                                                                                            "Order form link",
                                                                                        )
                                                                                    }
                                                                                    className="w-fit">
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                            <Tooltip id="asoebi_tooltip">
                                                                                                click to copy order form link
                                                                                            </Tooltip>
                                                                                        }>
                                                                                        <div>
                                                                                            <strong>Order form link: </strong>
                                                                                            <a
                                                                                                target="_blank"
                                                                                                href={order.questionnaire_link}
                                                                                                className="font-weight-bold">
                                                                                                View Order form
                                                                                            </a>
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            ) : (
                                                                                <a
                                                                                    target="_blank"
                                                                                    href={order.questionnaire_link}
                                                                                    className="font-weight-bold">
                                                                                    View Order form
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            {order?.paid_at ? (
                                                                                <div
                                                                                    onClick={() => copyToClipboardHandler(order.paid_at, "Paid At")}
                                                                                    className="w-fit">
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                            <Tooltip id="asoebi_tooltip">
                                                                                                click to copy paid at
                                                                                            </Tooltip>
                                                                                        }>
                                                                                        <div>
                                                                                            <strong>Paid At: </strong>
                                                                                            {format(new Date(order.paid_at), "dd/MM/yyyy")}
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <strong>Paid At: </strong>
                                                                                    {format(new Date(order.paid_at), "dd/MM/yyyy")}
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                        <div>
                                                                            <strong>Status:</strong>
                                                                            <SiteDropdown
                                                                                id="status_dropdown"
                                                                                title={orderText}
                                                                                variant="clear"
                                                                                arrow_fill="black"
                                                                                className="d-flex">
                                                                                {dropdownOptions.map((item) => {
                                                                                    return (
                                                                                        item.value !== order.status && (
                                                                                            <Dropdown.Item
                                                                                                key={item.text}
                                                                                                onClick={() =>
                                                                                                    updateOrderStatusHandler(
                                                                                                        item.value,
                                                                                                        order.event_name,
                                                                                                        order.id,
                                                                                                    )
                                                                                                }>
                                                                                                {item.text}
                                                                                            </Dropdown.Item>
                                                                                        )
                                                                                    );
                                                                                })}
                                                                            </SiteDropdown>
                                                                        </div>
                                                                        <div>
                                                                            <strong>Order deadline:</strong>
                                                                            {orderDeadlineDate}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            {paginatedDetails.length > 0 &&
                            <div className="paginate_event mt-5 pt-5 w-full d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <Select value={limit} onChange={onSelectEventLimit} input={selectLimitDetails} />
                                    <p className="mb-0 ml-2">
                                        Showing {showingAuditCounter} of {totalAudits}
                                    </p>
                                </div>
                                <div>
                                    <button className="border-0 bg-transparent mr-3" disabled={disableLeftArrowBtn} onClick={decrementPageCounter}>
                                        <ArrowleftIcon fill={leftArrowColor} />
                                    </button>
                                    <button className="border-0 bg-transparent ml-3" disabled={disableRightArrowBtn} onClick={incrementPageCounter}>
                                        <ArrowRightIcon fill={rightArrowColor} />
                                    </button>
                                </div>
                            </div>}
                        </div>
                    )}
                </>
            </AsoebiDashboardLayout>
        </>
    );
}
