import type {
    RequestErrorType,
    AsoebiQuestionnaireType,
    auditType,
    budgetType,
    categoryType,
    ceremonyType,
    eventType,
    exportBudgetType,
    pricingPlanPaymentType,
    submitAsoebiPayloadType,
    todoType,
    verifyPaymentType,
    RequestType,
    submitAsoebiTrackerPayloadType,
} from "@/types";
import axios, { use_budget_tracker_base_url } from "@/axios/config";
import {
    AddChecklistBody,
    AsoebiAdminType,
    AsoebiFormType,
    BudgetExportResponse,
    ModelResultResponse,
    PostResponseWithoutDataType,
    SaveNewUserBody,
    SaveNewUserResponse,
    UserStatusResponse,
    pricingPlanDetailsType,
    runModelBodyType,
} from "@/data/types";
import { addMonths } from "date-fns";
import { AxiosResponse } from "axios";

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export const run_model = async (body: runModelBodyType): Promise<ModelResultResponse> => {
    try {
        const data = await axios.post(`${use_budget_tracker_base_url}/api/v1/calculate`, body);

        return data.data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
};

export const exportBudget = async (body: exportBudgetType): Promise<BudgetExportResponse> => {
    try {
        const data = await axios.post(`${use_budget_tracker_base_url}/api/v1/export_budget`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
};

export async function saveNewUser(body: SaveNewUserBody): Promise<SaveNewUserResponse> {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/auth/save-new-user`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function checkUserStatus(uid: string): Promise<UserStatusResponse> {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/auth/check-user-status`, { uid });
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function createEvent(body: eventType): Promise<SaveNewUserResponse> {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/add`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function addChecklist(checklist: AddChecklistBody, eventId: string, ceremony_id: string): Promise<PostResponseWithoutDataType> {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/${eventId}/checklist/add`, { checklist, ceremony_id });
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function addMultipleEventChecklist(
    body: {
		tag: { id: string; name: string };
		checklists: todoType;
	},
    eventId: string,
): Promise<PostResponseWithoutDataType> {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/${eventId}/checklist/add-multiple`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function addCategory(body: categoryType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/category/add/${eventId}`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type onboardingCeremonyType = {
	ceremonies: Array<{
		id: string;
		name: string;
		location: string | null;
		no_of_guests: number | null;
		date: Date | null;
	}>;
	details: {
		user?: string | "Groom" | "Bride";
		budgetAmount?: number;
		hasBudget?: boolean;
		days?: number;
	};
};

export async function addCeremony(body: ceremonyType[0] | onboardingCeremonyType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/${eventId}/ceremony/add`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type formDetailsType = {
	name: string;
	email: string;
	phoneNumber: string;
	communicationMethod: string;
	vendorRequirement: string;
	vendorPlans: string;
	amountPaid: string;
	vendorDetails: Array<{
		vendorService: string;
		eventLocation: string;
		vendorEstimatedBudget: string;
		eventDate?: Date | null;
	}>;
};

export async function vendorAssistanceRequest(formDetails: formDetailsType) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/vendor-assistance`, { email: formDetails.email });
        await axios.post(`${BASE_URL}/api/v1/vendor-assistance-spreadsheet`, formDetails);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function changeUserPicture(formData: FormData, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/${eventId}/update-event-picture`, formData);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function addBudgetEstimate(budget: budgetType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/add-budget-estimate/${eventId}`, { budget });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function duplicateEvent(eventDetails: { id: string; name: string; event_date: Date; event_bg: string }) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/duplicate/${eventDetails.id}`, eventDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type defaultLocationType = Array<{id:number, location: string; no_of_guests: number}>
export async function addDefaultBudget(budget: defaultLocationType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/add-default-budget/${eventId}`, budget);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateAudit(audit: auditType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/update-audits/${eventId}`, audit);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function sendSignupEmail(name: string, email: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/email/send-signup-email`, { email, name });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function sendPricingPlanEmail(planDetails: pricingPlanDetailsType) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/send-pricing-plan-email`, planDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}
type sendBasicSubscriptionReminderEmailType = {
	name: string;
	email: string;
	plan: string;
	paid_collaborators: string[];
	access_duration: number;
};

export async function sendBasicSubscriptionReminderEmail(body: sendBasicSubscriptionReminderEmailType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/send-basic-subscription-ending-notice-email/${eventId}`, body);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updatePricingPlan(updatePricingPlan: {
	plan: pricingPlanPaymentType;
	monthDuration?: number;
	amount: number;
	max_events: number;
	collaborator_per_events: number;
	eventId: string;
}) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/update-pricing-plan/${updatePricingPlan.eventId}`, updatePricingPlan);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type tourGuideType = { user_profile: boolean } | { event_overview: boolean } | { budget_tracker: boolean } | { checklist: boolean };

export async function updateTourGuide(result: tourGuideType) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/update-tour-guide`, result);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function renewCollaboratorAccountRequest(duration: number, email: string, eventId: string) {
    const end_date = addMonths(new Date(), Number(duration));
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/renew-collaborator-account/${eventId}`, { duration, email, end_date });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function onboardCollaborator(user_id: string, event_id: string, inviter_id: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/user/${user_id}/onboard-invited-collaborator/${event_id}`, { inviter_id });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function verifyPayment({ reference_id, user_id, event_id, type, amount, actual_amount }: verifyPaymentType) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/payment/${reference_id}`, { user_id, event_id, type, amount, actual_amount });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function onboardUserDetails(
    id: string,
    body:
        | {
                name: string;
                tour_guide: {
                    user_profile: boolean;
                    event_overview: boolean;
                    budget_tracker: boolean;
                    checklist: boolean;
                };
          }
        | { onboarding_id: string },
) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/user/${id}/onboard`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}
export type createAsoebiType = AsoebiQuestionnaireType & {
	consentToTransactionPolicy: boolean;
	orderFormType: keyof typeof AsoebiFormType | ""
};
export async function generateQuestionnaireLink(
    questionnaire_details: createAsoebiType
) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/create-asoebi-questionnaire`, questionnaire_details);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function uploadQuestionnaireMedia(formData: FormData, questionnaire_id:string) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/upload-asoebi-questionnaire-images/${questionnaire_id}`, formData);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}


export async function verifyAsoebiPayment(
    referenceId: string, actual_amount: number,
    questionnaire_id: string, {name, email, event_name, environment} :{name:string, email:string, event_name: string, environment:string}
) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/verify-asoebi-payment/${referenceId}`, {
            actual_amount, questionnaire_id, name, email, event_name, environment});
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function submitAsoebiQuestionnaire(payload: submitAsoebiPayloadType | submitAsoebiTrackerPayloadType) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/submit-asoebi-questionnaire`, payload);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function sendMagicLink(email:string ) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/send-asoebi-magic-link`, { email });
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function createAdmin(email: string, data?:unknown) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/create-asoebi-admin`, { email, data});
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function verifyMagicLink(token: string): Promise<AxiosResponse<{ message: string; result: AsoebiAdminType; type: RequestType }>> {
    try {
        return await axios.post(`${BASE_URL}/api/v1/verify-asoebi-magic-link`, {token});
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}
