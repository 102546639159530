import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { differenceInCalendarDays, addDays, addMonths, getDate } from "date-fns";
import { ceremonyDataFromCalculatorType, ceremonyLocationDataType, ceremonyType } from "./types";

export function range(start: number, end: number, step = 1) {
    const output = [];
    if (typeof end === "undefined") {
        end = start;
        start = 0;
    }
    for (let i = start; i < end; i += step) {
        output.push(i);
    }
    return output;
}

export function validateEmail(email: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
}

export function errorMessage(message: string) {
    toast.error(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function successMessage(message: string) {
    toast.success(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function formatCurrency(x: string | number, currency = "₦") {
    return `${currency}${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export function calculateOtherMiscellaneousCost(total_budget: number): number {
    const cost = Math.floor(0.05 * total_budget);
    if (cost <= 1000000) {
        return cost;
    } else {
        return 1000000;
    }
}

// returns date in format dd-mm-yyyy
type formatType = "yyyy-mm-dd" | "dd-mm-yyyy";

export function formatDate(date: Date, format: formatType = "dd-mm-yyyy") {
    if (date) {
        const dateFormat = format === "dd-mm-yyyy" ? "es-CL" : "fr-CA";
        const _formatDate = new Date(date);
        const _date = _formatDate.toLocaleDateString(dateFormat);
        return _date;
    }
}

export function formatNumber(inputValue: string | number) {
    const _inputValue = inputValue.toLocaleString();
    const formattedValue = (Number(_inputValue.replace(/\D/g, "")) || "").toLocaleString();
    return formattedValue;
}

export function formatStringToNumber(value: string) {
    return typeof value === "string" ? parseFloat(value.replace(/,/g, "")) : value;
}

export function getEventCountDown(date: Date) {
    const formatDate: unknown = new Date(date);
    const currentDate: unknown = new Date();
    const _formatDate = formatDate as number;
    const _currentDate = currentDate as number;
    const diffTime = Math.abs(_formatDate - _currentDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
}

/**
 * if true, date is not within 14 days range.
 * if false, date is within 14 days range
 * @param {Date} date
 * @return {boolean}
 */
export function isDateWithin14daysRange(date: Date): boolean {
    const currentDate = new Date();
    const in14daysTime = currentDate.setDate(currentDate.getDate() + 14);
    const formatGivenDate = new Date(date);

    const compareDate = Number(formatGivenDate) > Number(in14daysTime);
    return compareDate;
}

export function reduceCurrentYearByOne(date: Date): Date {
    const currentDate = new Date(date);
    currentDate.setFullYear(currentDate.getFullYear() - 1);
    return currentDate;
}

export function capitalizeWord(_word: string) {
    const word = _word.charAt(0).toUpperCase() + _word.slice(1);
    return word;
}

export function formatBudgetCategoryId(category: string) {
    if (category.includes("_")) {
        const splitCategory = category.split("_");
        let _word = "";
        splitCategory.map((splittedCategory) => {
            const capitalizeCategory = capitalizeWord(splittedCategory);
            _word += `${capitalizeCategory} `;
        });

        const word = _word.trim();

        return word;
    } else {
        const _category = capitalizeWord(category);
        return _category;
    }
}

export function formatCeremony(
    ceremonyData: ceremonyDataFromCalculatorType,
    date: Date,
) {
    let ceremonies: ceremonyDataFromCalculatorType = [];
    ceremonyData.map((item) => {
        const _no_of_guest = typeof item.no_of_guests === "number" ?
            item.no_of_guests : item.no_of_guests ?
                formatStringToNumber(item.no_of_guests) : 0;
        ceremonies = [
            ...ceremonies,
            {
                ...item,
                id: uuidv4(),
                no_of_guests: _no_of_guest,
                name: item.name,
                date,
                checklist: [],
                show_suggestion: true,
            },
        ];
    });
    return ceremonies;
}


export function formatOnboardingCeremony(
    onboardingCeremonyData: ceremonyType,
    ceremonylocationDetails:ceremonyLocationDataType) {
    const ceremonies: ceremonyType = [];
    if (ceremonylocationDetails) {
        ceremonylocationDetails.map((item, index) => {
            const __ceremonyItem = onboardingCeremonyData[index] ? onboardingCeremonyData[index]: {};
            const ceremonyItem = __ceremonyItem as ceremonyType[0];
            return ceremonies.push({
                ...ceremonyItem,
                name: onboardingCeremonyData[index] ? onboardingCeremonyData[index].name : "",
                no_of_guests: item.no_of_guests,
                location: item.location,
            });
        },
        );
    }
    return ceremonies;
}

export function whenIsBalanceDue(balanceDateString: Date) {
    const balanceDate = new Date(balanceDateString);
    const currentDate = new Date();
    const daysDiff = differenceInCalendarDays(balanceDate, currentDate);

    if (daysDiff > 1) {
        return `Due in ${daysDiff} days`;
    } else if (daysDiff === 1) {
        return "Due tomorrow";
    } else if (daysDiff === 0) {
        return "Due today";
    }
}

export function convertNumberToCurrency(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const convertCurrencyToNumber = (x: string, currency: string) => {
    const amount = x.includes(currency) ? x.split(currency)[1] : x;
    const amountString = formatStringToNumber(amount);
    return Number(amountString);
};


export function selectDatePickerDay(period: number) {
    const currentDay = getDate(new Date());
    const periodDate = addDays(new Date(), period);
    const eventDate = currentDay > 22 ? addMonths(periodDate, 1) : periodDate;
    const getEventDateInNumber = getDate(eventDate);
    const eventDateInNumber = getEventDateInNumber > 9 ? `0${getEventDateInNumber}` : `00${getEventDateInNumber}`;
    return `.react-datepicker__day--${eventDateInNumber}`;
}
