import type { PropsWithChildren } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

import closeIcon from "@/assets/img/close.svg";

interface Props {
    title: string;
    show?: boolean;
    onHide?: () => void;
    children: JSX.Element;
    className?: string;
}

/**
 *
 * @param title
 * @param show
 * @param onHide
 * @param className
 * @param children
 * @returns
 */

export default function SiteModal({ title, show, onHide, children, className = "" }: PropsWithChildren<Props>) {
    return (
        <Modal show={show} onHide={onHide} className={`px-lg-4 px-2 ${className}`}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                {onHide && (
                    <Button onClick={onHide} variant="">
                        <img src={closeIcon} className="close_icon" alt="close modal" />
                    </Button>
                )}
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
}
