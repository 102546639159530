import { useQuery } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { usePaystackPayment } from "react-paystack";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { format, isToday } from "date-fns";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { getAsoebiQuesionnaireDetails } from "@/axios/get-request";
import CustomInput from "@/components/form/CustomInput";
import questionaire from "@/json/questionaire.json";
import CustomTextarea from "@/components/form/CustomTextarea";
import { formatCurrency, formatStringToNumber } from "@/helper";
import Button from "@/components/Button";
import payStackConfig from "@/payStackConfig";
import { initializePaymentType, paystackReferenceType } from "@/data/types";
import { submitAsoebiQuestionnaire, verifyAsoebiPayment } from "@/axios/post-request";
import Input from "@/components/form/Input";
import MinusIcon from "@/assets/icon/MinusIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import AsoebiOrderformLoader from "@/components/loaders/AsoebiOrderformLoader";

const DEADLINE_HOUR = 23;
const DEADLINE_MINUTE = 59;

interface Props {
    questionnaireId: string;
}
type SelectedAsoebiOptionType = Array<{ option: string; price: string; max_stock_qty: number; stock_bought_qty: number; qty: number }>;

type questionaireType = {
    name: string;
    email?: string;
    phone_number: string;
    delivery_address?: string;
    pickup_address?: string;
    pickup_phone_number?: string;
    special_note?: string;
};

const schema = yup.object({
    name: yup.string().required("Name is required").min(3),
    phone_number: yup.string().required("Phone number is required"),
});

export default function AsoebiQuestionnaire({ questionnaireId }: Props) {
    const [selectedAsoebiOptions, setSelectedAsoebiOptions] = useState<SelectedAsoebiOptionType>([]);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const methods = useForm<questionaireType>({
        resolver: yupResolver(schema),
    });
    const { data, status } = useQuery({
        queryKey: [`questionnaire__${questionnaireId}`],
        queryFn: () => getAsoebiQuesionnaireDetails(questionnaireId),
        enabled: !!questionnaireId,
    });

    const asoebiOptions: SelectedAsoebiOptionType = [];

    useEffect(() => {
        if (selectedAsoebiOptions.length === 0 && status === "success") {
            data.result.asoebiOptions.options.map((option) => asoebiOptions.push({ ...option, qty: 0 }));

            setSelectedAsoebiOptions(asoebiOptions);
        }
    }, [status]);

    function updateEmail(event: ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function qtyControlHandler(type: "decrement" | "increment", index: number, maxQty: number) {
        if (selectedAsoebiOptions[index].qty + 1 > maxQty && type === "increment") {
            return toast.info(`Maximum available stock quantity for ${selectedAsoebiOptions[index].option} is ${maxQty}`);
        }
        const selectedOptionsTemp = selectedAsoebiOptions.map((selectedOptn, idx) => {
            if (idx === index) {
                if (type === "decrement" && selectedOptn.qty > 0) {
                    selectedOptn.qty = selectedOptn.qty - 1;
                } else if (type === "increment") {
                    selectedOptn.qty = selectedOptn.qty + 1;
                }
                return selectedOptn;
            } else {
                return selectedOptn;
            }
        });

        setSelectedAsoebiOptions(selectedOptionsTemp);
    }

    const asoebiImages = data?.result?.asoebiOptions?.images ? (data?.result?.asoebiOptions?.images?.length <= 1 ? "single" : "many") : "none";
    const asoebiImagesClassName = status === "success" ? asoebiImages : null;

    let total = 0;
    if (selectedAsoebiOptions.length > 0) {
        total = selectedAsoebiOptions.reduce((a, b) => {
            const amount = formatStringToNumber(b.price) * b.qty;
            return a + amount;
        }, 0);
    }
    const config = payStackConfig(email, total);
    const initializePayment = usePaystackPayment(config) as initializePaymentType;

    const currency = data?.result.asoebiOptions.currency?.includes("Dollar") ? "$" : "₦";

    const totalAmount = formatCurrency(total, currency);

    const mode_of_communication_text =
        data?.result.asoebiOptions.mode_of_communication === "Email" ?
            "email" :
            data?.result.asoebiOptions.mode_of_communication === "Whatsapp" ?
                "whatsapp" :
                " ";
    const mode_of_communication_link =
        mode_of_communication_text === "email" ? (
            <a href={`mailto:${data?.result?.asoebiOptions.email_address_for_communication}`}>
                {data?.result?.asoebiOptions?.email_address_for_communication}
            </a>
        ) : (
            <a href={`https://wa.me/${data?.result?.asoebiOptions?.whatsapp_number}?text=Hello`}>{data?.result?.asoebiOptions?.whatsapp_number}</a>
        );

    function onCloseHandler() {
        setLoading(false);
        return toast.error("Unable to make payment");
    }

    const orderDeadline =
        status === "success" && data.result.asoebiOptions.order_deadline ?
            format(new Date(data.result.asoebiOptions.order_deadline), "dd/MM/yyyy") :
            null;

    const currentDateInstance = new Date();
    const orderDeadlineDateInstance = status === "success" ? new Date(data.result.asoebiOptions.order_deadline) : null;
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();

    const expiresToday =
		orderDeadlineDateInstance && isToday(orderDeadlineDateInstance) ? (currentHour >= DEADLINE_HOUR && currentMinute >= DEADLINE_MINUTE) : false;

    const expiryDateCheck = orderDeadlineDateInstance ? (currentDateInstance > orderDeadlineDateInstance) : false;

    const hasFormExpired = orderDeadlineDateInstance && isToday(orderDeadlineDateInstance) ? expiresToday : expiryDateCheck;

    async function submitOrderDetails(formDetails: questionaireType) {
        const validAsoebiOptions: SelectedAsoebiOptionType = [];
        selectedAsoebiOptions.forEach((item) => {
            if (item.qty > 0) {
                validAsoebiOptions.push(item);
            }
        });
        const payload = {
            ...formDetails,
            email,
            options: validAsoebiOptions,
            amount_paid: totalAmount,
            vendor_slug: data?.result.vendor_slug,
            questionnaire_id: data?.result.id,
            event_name: data?.result.eventDetails.event_name,
            event_owner_name: data?.result.eventDetails.name,
            event_owner_email: data?.result.eventDetails.email,
            questionnaire_link: data?.result.questionnaire_link,
        };
        setLoading(false);

        await submitAsoebiQuestionnaire({...payload});
        methods.reset();
        const defaultAsobeiOptions: SelectedAsoebiOptionType = [];
        asoebiOptions.forEach((item) => {
            defaultAsobeiOptions.push({ ...item, qty: 0 });
        });
        setSelectedAsoebiOptions(defaultAsobeiOptions);
        setLoading(false);
        setEmail("");
        toast.success("Asoebi order form submitted successfully");
        return navigate("/asoebi-tracker/questionnaire-answered");
    }

    async function makePaymentHandler(response: paystackReferenceType, formDetails: questionaireType) {
        const validAsoebiOptions: SelectedAsoebiOptionType = [];
        selectedAsoebiOptions.forEach((item) => {
            if (item.qty > 0) {
                validAsoebiOptions.push(item);
            }
        });
        const payload = {
            ...formDetails,
            email,
            options: validAsoebiOptions,
            amount_paid: totalAmount,
            vendor_slug: data?.result.vendor_slug,
            questionnaire_id: data?.result.id,
            event_name: data?.result.eventDetails.event_name,
            event_owner_name: data?.result.eventDetails.name,
            event_owner_email: data?.result.eventDetails.email,
            questionnaire_link: data?.result.questionnaire_link,
        };
        const verifyPaymentRequest = await verifyAsoebiPayment(response.reference, total, data?.result.id as string, {
            name: formDetails.name,
            event_name: data?.result.eventDetails.event_name as string,
            environment: window.location.href?.includes("staging") || window.location.href?.includes("localhost") ? "STAGING" : "PRODUCTION",
            email,
        });

        setLoading(false);
        if (verifyPaymentRequest.status !== 200) {
            return toast.error(verifyPaymentRequest?.data?.message || "Invalid payment");
        }
        await submitAsoebiQuestionnaire({
            ...payload,
            paystack_reference_id: verifyPaymentRequest.data.result.reference_id,
            paystack_doc_id: verifyPaymentRequest.data.result.paystack_doc_id,
        });
        methods.reset();
        const defaultAsobeiOptions: SelectedAsoebiOptionType = [];
        asoebiOptions.forEach((item) => {
            defaultAsobeiOptions.push({ ...item, qty: 0 });
        });
        setSelectedAsoebiOptions(defaultAsobeiOptions);
        setLoading(false);
        setEmail("");
        toast.success("Asoebi order form submitted successfully");
        return navigate("/asoebi-tracker/questionnaire-answered");
    }

    async function onSubmitHandler(formDetails: questionaireType) {
        try {
            if (!email) {
                return toast.error("Please enter your email address");
            }
            if (!formDetails.delivery_address && data?.result.guestDetails.delivery_address) {
                return toast.error("Please enter your delivery address");
            }
            if (total === 0) {
                return toast.error("Please select asoebi");
            }
            setLoading(true);

            const dataRequest = await getAsoebiQuesionnaireDetails(questionnaireId);

            selectedAsoebiOptions.forEach((selectedAsoebiOption) => {
                if (selectedAsoebiOption.qty > 0) {
                    dataRequest.result.asoebiOptions.options.filter((item) => {
                        if (item.option === selectedAsoebiOption.option) {
                            const availableItem = item.max_stock_qty - item.stock_bought_qty;
                            if (availableItem === 0) {
                                throw new Error(`${item.option} is out of stock`);
                            }
                            if (selectedAsoebiOption.qty > availableItem) {
                                throw new Error(`Only ${availableItem} of ${item.option} left, please update current quantity`);
                            }
                        }
                    });
                }
            });
            if (data?.result?.asoebiOptions?.currency?.includes("Dollar")) {
                return submitOrderDetails(formDetails);
            }
            initializePayment((response) => {
                makePaymentHandler(response, formDetails);
            }, onCloseHandler);
        } catch (error: unknown) {
            const __error = error as { message: string };
            setLoading(false);
            console.error(error);
            return toast.error(__error.message);
        }
    }

    const buttonText = data?.result?.asoebiOptions?.currency?.includes("Dollar") ? "Proceed" : "Make Payment";

    return (
        <section className="tracker_content asoebi_questionnaire">
            {status === "error" ? (
                <div className="vh-100">
                    <h3 className="text-center font-weight-bold text-xl">Oops unable to fetch asoebi order form details</h3>
                </div>
            ) : status === "loading" ? (
                <AsoebiOrderformLoader />
            ) : (
                data?.result && (
                    <>
                        <h1>
                            <span>{data.result.eventDetails.event_name}</span> Asoebi Order Form
                        </h1>
                        {orderDeadline && (
                            <h4 className="font-weight-bold d-flex align-items-center mx-auto justify-content-center">
                                Order deadline: {orderDeadline}{" "}
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="order_deadline" className="px-0">
                                            This form will be disabled once the deadline elapses.
                                        </Tooltip>
                                    }>
                                    <span className="question_misc">?</span>
                                </OverlayTrigger>
                            </h4>
                        )}

                        {hasFormExpired ? (
                            <div className="vh-55 mt-5">
                                <h1>This form has expired 😔</h1>
                                <p className="text-center my-4">Order form has expired kindly reachout to the event organizer</p>
                            </div>
                        ) : (
                            <>
                                <div
                                    className={`asoebi_images my-4 ${asoebiImagesClassName} mx-auto 
                                justify-content-center col-lg-8 col-md-11 col-12 d-flex`}>
                                    {data.result.asoebiOptions?.images &&
                                        data.result.asoebiOptions?.images.map((image, index) => {
                                            return <img key={index} src={image} alt="Asoebi image" />;
                                        })}
                                </div>
                                <div className="form_view">
                                    <FormProvider {...methods}>
                                        <form
                                            className="my-4 questionnaire col-lg-8 col-md-11 col-12 d-flex flex-column px-0 mx-auto"
                                            onSubmit={methods.handleSubmit(onSubmitHandler)}>
                                            {hasFormExpired && (
                                                <div className="overlay">
                                                    <h1>This form has expired 😔</h1>
                                                </div>
                                            )}
                                            {data.result.guestDetails.name && (
                                                <CustomInput error={methods.formState.errors["name"]} input={questionaire.name} />
                                            )}
                                            <Input value={email} input={questionaire.email} onChange={updateEmail} />
                                            {data.result.guestDetails.phone_number && (
                                                <CustomInput error={methods.formState.errors["phone_number"]} input={questionaire.phone_number} />
                                            )}
                                            {data.result.guestDetails.delivery_address && <CustomInput input={questionaire.delivery_address} />}
                                            {data.result.asoebiOptions.special_note && (
                                                <CustomTextarea input={questionaire.special_note} readOnly={false} />
                                            )}

                                            <div className="d-flex select_asoebi_options flex-column mt-1">
                                                <h4 className="text-left my-0 mx-0">Select Asoebi</h4>
                                                {selectedAsoebiOptions.map((item, index) => {
                                                    const { option, price, qty } = item;
                                                    const label = `${option} - ${currency}${price}`;
                                                    const amount = formatStringToNumber(price) * qty;
                                                    const amountInCurrency = formatCurrency(amount, currency);
                                                    const availableQty = item.max_stock_qty - item.stock_bought_qty;
                                                    const disableBtn = item.max_stock_qty <= item.stock_bought_qty;
                                                    return (
                                                        <Fragment key={index}>
                                                            <div>
                                                                <div
                                                                    key={index}
                                                                    className="bg-white d-flex flex-lg-row flex-column align-items-lg-center
                                                justify-content-lg-between py-2 px-md--4 px-3 rounded shadow-sm">
                                                                    <div>{label}</div>
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <div className="mx-lg-3 mr-3 mr-lg-0">{amountInCurrency}</div>
                                                                        <div className="d-flex flex-row align-items-center qty_control_group">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn__minus d-flex justify-content-center"
                                                                                onClick={() => qtyControlHandler("decrement", index, availableQty)}
                                                                                disabled={disableBtn}
                                                                            >
                                                                                <MinusIcon />
                                                                            </button>
                                                                            <span className="mx-3">{qty}</span>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn__plus d-flex align-items-center"
                                                                                onClick={() => qtyControlHandler("increment", index, availableQty)}
                                                                                disabled={disableBtn}
                                                                            >
                                                                                <PlusIcon />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {item.max_stock_qty <= item.stock_bought_qty && (
                                                                    <p className="text-danger my-1 small">This item is out of stock</p>
                                                                )}
                                                            </div>
                                                        </Fragment>
                                                    );
                                                })}
                                                <div className="d-flex w-full justify-content-between align-items-center">
                                                    <h4 className="px-0 text-left my-0 mx-0">Total</h4>
                                                    <h4 className="font-weight-bold mx-0 my-3">{totalAmount}</h4>
                                                </div>
                                                <div className="mb-4">
                                                    <div className="mt-0 mb-1">
                                                        You can reach out to <span>{mode_of_communication_link}</span> via{" "}
                                                        {mode_of_communication_text} if you have any questions.
                                                    </div>
                                                    {data.result.guestDetails?.collection_option.includes("Pickup") && (
                                                        <>
                                                            {data.result.guestDetails?.pickup_address && (
                                                                <p className="my-1">Pickup address: {data.result.guestDetails.pickup_address}</p>
                                                            )}
                                                            {data.result.guestDetails?.pickup_phone_number && (
                                                                <p className="my-1">
                                                                    Pickup phone number:{" "}
                                                                    <a href={`tel:${data.result.guestDetails.pickup_phone_number}`}>
                                                                        {data.result.guestDetails.pickup_phone_number}
                                                                    </a>
                                                                </p>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className="mt-3 mb-1">
                                                        Reach out to <a href="mailto:help@planaday.events">help@planaday.events</a> for support
                                                    </div>
                                                </div>
                                                {!hasFormExpired && (
                                                    <Button
                                                        isLoading={loading}
                                                        type="submit"
                                                        text={buttonText}
                                                        className="mx-auto btn btn-primary text-white font-weight-bold"
                                                    />
                                                )}
                                            </div>
                                        </form>
                                    </FormProvider>
                                </div>
                            </>
                        )}
                    </>
                )
            )}
        </section>
    );
}
