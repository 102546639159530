import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { ChangeEvent } from "react";

import Button from "@/components/Button";
import SiteModal from "@/components/modals/SiteModal";
import { changeUserPicture } from "@/axios/post-request";
import UploadIcon from "@/assets/icon/UploadIcon";
import { useEvent } from "@/hooks/useEvent";

interface Props {
    show: boolean;
    imageURL: string | null;
    eventBg?: string;
    onHide: () => void;
}

export default function ChangePhotoModal({ show, onHide, eventBg, imageURL }: Props) {
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<null | string>(null);
    const queryClient = useQueryClient();
    const { selectedEventId } = useEvent();
    const formData = new FormData();

    async function uploadProfilePicture() {
        const _file = file as File;
        formData.append("file", _file);
        return await changeUserPicture(formData, selectedEventId);
    }

    const { mutate, isLoading } = useMutation({
        mutationKey: ["changeUserPicture"],
        mutationFn: () => uploadProfilePicture(),
        onSuccess: (data) => {
            toast.success(data?.data.message);
            queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
            onHide();
        },
        onError: (error: unknown) => {
            const _error = error as { message: string };
            toast.error(_error.message);
        },
    });

    const image = preview ? preview : imageURL;

    function changePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];

        const url = URL.createObjectURL(file);
        setPreview(url);
        setFile(file);
    }

    function onSubmitHandler(e: ChangeEvent<HTMLFormElement>) {
        e.preventDefault();
        if (file) {
            mutate();
        } else {
            toast.error("please select your file");
        }
    }

    return (
        <SiteModal title="Change Photo" show={show} onHide={onHide}>
            <form
                method="POST"
                onSubmit={onSubmitHandler}
                className="d-flex my-4 my-lg-0 change_photo_modal flex-column mx-auto justify-content-center align-items-center"
                encType="multipart/form-data">
                <div className="position-relative d-flex align-items-center justify-content-center">
                    <input type="file" id="file" name="file" className="position-absolute" onChange={changePhotoHandler} />
                    <label htmlFor="file">
                        {image ? (
                            <img src={image} alt="User Profile icon" className="rounded-circle mb-3" />
                        ) : (
                            <div className="event_bg_banner" style={{ backgroundColor: eventBg }} />
                        )}
                        <div className="position-absolute z-10 upload_icon_wrapper">
                            <UploadIcon />
                        </div>
                    </label>
                </div>
                <Button className="bg_red mt-5 text-white font-weight-bold mb-3" text="Change Photo" type="submit" isLoading={isLoading} />
            </form>
        </SiteModal>
    );
}
