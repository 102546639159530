import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import type { ChangeEvent } from "react";

import SiteModal from "@/components/modals/SiteModal";
import Input from "@/components/form/Input";
import Button from "@/components/Button";
import ceremony_modal_content from "@/json/form/budget_tracker_modal.json";
import SelectDateInput from "@/components/form/SelectDateInput";
import { addCeremony, updateAudit } from "@/axios/post-request";
import { formatNumber } from "@/helper";
import useUser from "@/hooks/useUser";
import { auditActionType, eventType } from "@/types";

interface Props {
	show: string | null;
	onHide: () => void;
	ceremonies?: eventType["ceremonies"]
}

type ceremonyType = {
    name: string;
    location: string;
    no_of_guests: string;
    date: Date | null;
};

export default function AddNewCeremonyModal({ show, onHide, ceremonies }: Props) {
    const { ceremony: event_ceremony } = ceremony_modal_content;
    const { data: userData, selectedEventId } = useUser();
    const [ceremony, setCeremony] = useState<ceremonyType>({
        name: "",
        location: "",
        no_of_guests: "",
        date: null,
    });
    const queryClient = useQueryClient();

    function addNewCeremony() {
        if (!ceremony.name) {
            return toast.error("Ceremony name is required");
        }
        if (!ceremony.location) {
            return toast.error("Ceremony location is required");
        }
        if (!ceremony.date) {
            return toast.error("Ceremony date is required");
        }
        mutate();
    }


    const { mutate, isLoading } = useMutation({
        mutationKey: ["addCeremony"],
        mutationFn: () => addCeremony({ ...ceremony, id: uuidv4(), checklist: [], show_suggestion: true }, selectedEventId),
        onMutate: () => {
            const ceremonyWithSimilarNameList = ceremonies?.filter((ceremonyItem) => ceremonyItem.name.toLowerCase() === ceremony.name.toLowerCase());
            if (ceremonyWithSimilarNameList && ceremonyWithSimilarNameList.length > 0) {
                toast.error("Ceremony with similar name exist");
                throw Error("Ceremony with similar name exist");
            }
        },
        onSuccess: () => {
            toast.success("Ceremony added");
            updateAudit(
                [
                    {
                        id: uuidv4(),
                        name: userData?.result?.name as string,
                        user_id: userData?.result?.id as string,
                        email: userData?.result?.email as string,
                        type: "general",
                        action: auditActionType.add_ceremony,
                        changes: {
                            from: {
                                name: "",
                                location: "",
                                guest: null,
                                date: null,
                            },
                            to: {
                                name: ceremony.name,
                                location: ceremony?.location as string,
                                guest: Number(ceremony?.no_of_guests) as number,
                                date: ceremony.date ? new Date(ceremony.date) : null,
                            },
                        },
                        title: ceremony.name,
                        timestamp: new Date(),
                    },
                ],
                selectedEventId,
            );
            queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
            queryClient.resetQueries({ queryKey: [`fetch_ceremonies_${selectedEventId}`] });
            onHide();
        },
        onError: (error) => {
            const _error = error || "error adding ceremony";
            toast.error(_error);
        },
    });

    const showModal = show === "add_ceremony_modal" ? true : false;

    function setDateHandler(date: Date) {
        setCeremony({
            ...ceremony,
            date,
        });
    }

    function inputChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        const inputValue = e.target.name === "no_of_guests" ? formatNumber(e.target.value) : e.target.value;
        setCeremony({
            ...ceremony,
            [e.target.name]: inputValue,
        });
    }

    const currentDate = new Date();

    return (
        <SiteModal title="Add Ceremony" show={showModal} onHide={onHide}>
            <section className="add_new_ceremony">
                <p>Create a new ceremony for your event.</p>
                <form className="mt-5 mb-3 modal_form">
                    {event_ceremony.columns.map((input) => {
                        const inputName = input.name as keyof ceremonyType;
                        const name = ceremony[inputName] as string;
                        return <Input input={input} key={input.name} value={name} onChange={inputChangeHandler} />;
                    })}
                    <div className="input_row d-flex flex-md-row flex-column align-items-center justify-content-between">
                        <SelectDateInput className="pr-md-3 px-0" label="Date" minDate={currentDate} onClick={setDateHandler} />
                        <Input
                            input={event_ceremony.row}
                            className="col-md-6 pl-md-3 px-0"
                            value={ceremony.no_of_guests}
                            onChange={inputChangeHandler}
                            maxLength={5}
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button text="Save" className="bg_red text-white mt-2" isLoading={isLoading} onClick={addNewCeremony} />
                    </div>
                </form>
            </section>
        </SiteModal>
    );
}
