import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import Button from "@/components/Button";
import BudgetListDropdown from "@/components/BudgetListDropdown";
import addIcon from "@/assets/img/add.svg";
import { useFilter } from "@/hooks/useFilter";
import DeleteBudgetCostItemModal from "@/components/modals/DeleteBudgetCostItemModal";
import type { categoryType, costItemType } from "@/types";
import { updateCostItems } from "@/axios/put-request";
import { useEvent } from "@/hooks/useEvent";

type addCostType = Array<costItemType>;

interface Props {
    category: categoryType;
}

export default function AddBudgetList({ category }: Props) {
    const { ceremony, } = useFilter();
    const { selectedEventId} = useEvent();
    const queryClient = useQueryClient();

    const categoryCostItem = category.cost_items.length === 0 ? [] : category.cost_items;
    const [addCost, setAddCost] = useState<addCostType>(categoryCostItem);

    async function addCostHandler() {
        const defaulCategoryCostitem = {
            id: uuidv4(),
            name: "New Cost",
            estimate: 0,
            actual: 0,
            vendor_ig: "",
            balance: 0,
            note: "",
            balance_due: null,
            paid: 0,
            vendor_name: "",
            tag: "",
            paid_by_me: "",
        };
        setAddCost((prev) => [...prev, defaulCategoryCostitem]);
        await updateCostItems(category.id, defaulCategoryCostitem, selectedEventId);
        return queryClient.invalidateQueries({ queryKey: [`get_categories_${selectedEventId}`], exact: true });
    }

    function deleteCostItemHandler(id: string) {
        const categoryCostItem = addCost.filter((item) => item.id !== id);
        setAddCost(categoryCostItem);
    }

    const costItemList = ceremony?.id !== "all" ? addCost.filter((_costItem) => _costItem.tag === ceremony?.id || _costItem.tag === "") : addCost;

    return (
        <>
            <DeleteBudgetCostItemModal categoryName={category.name} deleteCategoryCostItem={deleteCostItemHandler} />
            <div className="budget_dropdown">
                <hr className="divider" />
                <div className="budget_dropdown_list">
                    {costItemList.length > 0 ? (
                        costItemList.map((item, index) => (
                            <BudgetListDropdown key={item.id} index={index} categoryId={category.id} costItem={item} categoryName={category.name} />
                        ))
                    ) : (
                        <p>No available budget category cost item, click on "Add cost item" to add a new cost item</p>
                    )}
                </div>
                <Button text="Add cost item" className="bg_red text-white my-1" icon={addIcon} onClick={addCostHandler} />
            </div>
        </>
    );
}
