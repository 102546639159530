/* eslint-disable max-len */

interface Props {
	fill?: string;
}
export default function PlusIcon({ fill = "white"}: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 18" fill={fill}>
            <path
                d="M14.8571 7.2246H9.71429V1.78498C9.71429 1.11749 9.2025 0.576172 8.57143 0.576172H7.42857C6.7975 0.576172 6.28571 1.11749 6.28571 1.78498V7.2246H1.14286C0.511786 7.2246 0 7.76592 0 8.43341V9.64221C0 10.3097 0.511786 10.851 1.14286 10.851H6.28571V16.2906C6.28571 16.9581 6.7975 17.4994 7.42857 17.4994H8.57143C9.2025 17.4994 9.71429 16.9581 9.71429 16.2906V10.851H14.8571C15.4882 10.851 16 10.3097 16 9.64221V8.43341C16 7.76592 15.4882 7.2246 14.8571 7.2246Z"
                fill={fill}
            />
        </svg>
    );
}
