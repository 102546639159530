import { useEffect, Suspense, useState, useRef } from "react";
import FuzzySearch from "fuzzy-search";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import useToast from "@/hooks/useToast";
import Button from "@/components/Button";
import addIcon from "@/assets/img/add.svg";
import ChecklistItem from "@/components/ChecklistItem";
import ChecklistLoader from "@/components/loaders/ChecklistLoader";
import { useChecklist } from "@/hooks/useChecklist";
import { useSearchChecklist } from "@/hooks/useSearchChecklist";
import EditChecklistItemModal from "@/components/modals/EditChecklistItemModal";
import DeleteModal from "@/components/modals/DeleteModal";
import { deleteChecklist } from "@/axios/delete-request";
import SuggestedChecklist from "@/components/SuggestedChecklist";
import sortTodos from "@/utils/sortTodos";
import { useFilter } from "@/hooks/useFilter";
import { updateAudit } from "@/axios/post-request";
import useUser from "@/hooks/useUser";
import useMyEvents from "@/hooks/useMyEvents";


const tabs = ["To-do", "Completed"];

interface Props {
	status: "success" | "loading" | "error";
	data?: {
		name: string;
		id: string;
		email: string;
	};
}

export default function ChecklistTodo({ status }: Props) {
    const queryClient = useQueryClient();
    const [tab, setTab] = useState("To-do");
    const { todo: checklist, setChecklistModal, checklistModal, selectectedChecklist, setSelectectedChecklist } = useChecklist();
    const { search, setSearching } = useSearchChecklist();
    const [searchChecklist, setSearchChecklist] = useState(checklist);
    const { loadingToast, updateToast } = useToast();
    const { ceremony } = useFilter();
    const { activeEvent } = useMyEvents();
    const { data, selectedEventId } = useUser();
    const toastId = useRef("deleteChecklist");

    const ceremoniesDateAndId: Array<{ id: string; date: Date | null }> = [];
    const eventCeremonies = activeEvent ? activeEvent[0]?.ceremonies : [];
    eventCeremonies?.map((item) => ceremoniesDateAndId.push({ date: item?.date, id: item.id }));
    const currentCeremony = eventCeremonies.filter((ceremonyItem) => ceremonyItem.id === ceremony.id);
    const showSuggestion = currentCeremony.length > 0 ? !!currentCeremony[0]?.show_suggestion : false;

    function closeModalHandler() {
        setChecklistModal(null);
    }

    useEffect(() => {
        if (search) {
            setSearching(true);
            const searcher = new FuzzySearch(checklist, ["name"]);
            const result = searcher.search(search);
            setSearchChecklist(result);
            if (result) {
                setSearching(false);
            }
        }
    }, [search]);

    function showModalHandler() {
        setChecklistModal("add_checklist_modal");
    }

    const completedTask = checklist.filter((item) => item.isDone);
    const todoTask = checklist.filter((item) => !item.isDone);

    const displayTodo = tab === "To-do" ? todoTask : completedTask;
    const checklistArray = search ? searchChecklist : displayTodo;

    const ceremonyChecklist = ceremony?.id !== "all" ? checklistArray.filter((checklist) => checklist.ceremony.id === ceremony?.id) : checklistArray;

    const sortedChecklistArray = sortTodos(ceremonyChecklist);

    const ceremonyId = selectectedChecklist?.ceremony.id as string;
    const checklistId = selectectedChecklist?.id as string;

    const { mutate, isLoading } = useMutation({
        mutationKey: ["delete_checklist"],
        onMutate: () => loadingToast(toastId),
        mutationFn: () => deleteChecklist(ceremonyId, checklistId, selectedEventId),
        onSuccess: ({ data: resultData }) => {
            updateAudit(
                [{
                    id: uuidv4(),
                    name: data?.result?.name as string,
                    user_id: data?.result?.id as string,
                    email: data?.result?.email as string,
                    type: "checklist",
                    action: "delete_checklist",
                    title: selectectedChecklist?.name ? selectectedChecklist?.name : "",
                    timestamp: new Date(),
                }],
                selectedEventId,
            );
            setSelectectedChecklist(null);
            closeModalHandler();
            updateToast(toastId, "success", resultData.message);
            queryClient.invalidateQueries({ queryKey: [`get_event_${selectedEventId}`], exact: true });
        },
        onError: (error: unknown) => {
            const _error = error as { message: string };
            updateToast(toastId, "error", _error?.message || "error deleting checklist");
        },
    });

    const todoDefaultText = tab === "To-do" ? "Add a todo, by clicking on 'Add Item'" : "No checklist item completed yet";

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                {checklistModal === "edit_checklist_modal" && selectectedChecklist ? (
                    <EditChecklistItemModal
                        checklist={selectectedChecklist}
                        show={checklistModal === "edit_checklist_modal"}
                        onHide={closeModalHandler}
                    />
                ) : (
                    checklistModal === "delete_checklist_modal" &&
					selectectedChecklist && (
                        <DeleteModal
                            show={checklistModal === "delete_checklist_modal"}
                            title={`${selectectedChecklist.name} checklist`}
                            onDelete={mutate}
                            isLoading={isLoading}
                            onHide={closeModalHandler}
                        />
                    )
                )}
            </Suspense>
            <Button className="bg_dark d-flex d-lg-none with_icon text-white mb-3" text="Add Item" icon={addIcon} onClick={showModalHandler} />
            <div className="checklist_todo bg-white">
                <div className="todo-header pt-3 pb-0 d-flex align-items-center justify-content-between">
                    <div className="todo-left d-flex align-items-center">
                        {tabs.map((tabItem, index) => {
                            const activeTab = tabItem === tab ? "active" : "";
                            const firstTabStyle = index === 0 ? "mr-3" : "";
                            return (
                                <Button key={tabItem} text={tabItem} className={`${activeTab} ${firstTabStyle}`} onClick={() => setTab(tabItem)} />
                            );
                        })}
                    </div>
                    <Button
                        id="add_checklist"
                        className="bg_dark hidden_on_mobile d-none d-lg-flex with_icon text-white mb-3"
                        text="Add Item"
                        icon={addIcon}
                        onClick={showModalHandler}
                    />
                </div>
                <div className="todo-body">
                    {showSuggestion && <SuggestedChecklist eventId={selectedEventId} ceremonyId={currentCeremony[0]?.id} />}
                    {status === "error" ? (
                        <p>Oops, unable to fetch checklist</p>
                    ) : status === "loading" ? (
                        <ChecklistLoader />
                    ) : displayTodo.length > 0 ? (
                        sortedChecklistArray?.map((todoItem, index) => (
                            <ChecklistItem
                                key={index}
                                ceremoniesDateAndId={ceremoniesDateAndId}
                                todoItem={todoItem}
                                activeEvent={activeEvent}
                            />
                        ))
                    ) : (
                        <p>{todoDefaultText}</p>
                    )}
                </div>
            </div>
        </>
    );
}
