export enum EventCategory {
	STANDARD = "standard",
	MID_LUXURY = "midluxury",
	LUXURY = "luxury",
}

export enum ItemCategory {
	PLANNING_AND_DECOR = "planning_and_decoration",
	FOOD_AND_DRINK = "food_drink",
	WEDDING_APPAREL = "wedding_apparel",
	ENTERTAINMENT = "entertainment_and_media",
	RENTALS = "rentals",
	SECURITY_STAFFING = "security_and_staffing",
	STATIONARIES = "stationaries",
	MISCELLANOUS = "miscellaneous",
}

export enum PriceType {
	UNIT = "unit",
	PER_PERSON = "per_person",
	PER_PLATE = "per_plate",
}

export enum Location {
	MAINLAND = "mainland",
	ISLAND = "island",
	PORT_HARCOURT = "Port-Harcourt",
	OTHER = "Other",
	ABUJA = "Abuja",
	IBADAN = "Ibadan",
}

export interface ModelInput {
	location: Location;
	no_of_guests: number;
}

export interface ModelResultCost {
	cost_standard: number;
	cost_mid_luxury: number;
	cost_luxurious: number;
}

export interface ModelResultItemBreakdown extends ModelResultCost {
	item: string;
	item_code: string;
	category: ItemCategory;
	checked: boolean;
}

export interface ModelResultSummary {
	standard_total: number;
	mid_luxury_total: number;
	luxurious_total: number;
}

export interface ModelResult {
	breakdown: ModelResultItemBreakdown[];
	summary: ModelResultSummary;
}
export interface ModelResultResponse extends ModelResult {
	status: boolean;
	message: string;
}

export type LocationAndGuests = {
	location: Location;
	no_of_guests: number;
};

export type BudgetExportResponse = {
	data: {
		message: string;
	};
	status: number;
};

export type SaveNewUserResponse = {
	data: {
		message: string;
		type: string;
	};
	status: number;
};

export type UserStatusResponse = {
	data: {
		message: string;
		type: string;
		result: boolean;
	};
	status: number;
};

export type AddChecklistResponse = {
	data: {
		message: string;
	};
	status: number;
};

export type PostResponseWithoutDataType = {
	data: {
		message: string;
	};
	status: number;
};

export type AddChecklistBody = {
	name: string;
	note: string | null;
	due: Date | null;
	isDone: boolean;
	ceremony: { name: string; id: string };
	id: string;
};

export type SaveNewUserBody = {
	uid: string;
	email: string | null;
	photo_url: string | null;
	phone_number: string | null;
	display_name: string | null;
	email_verified: boolean;
};

export type budgetForm = {
	user: string;
	budget: number;
	hasBudget: boolean;
	days: number;
};

export enum ScreenCategory {
	BUDGET_FORM_SCREEN = "Budget Form Screen",
	CALCULATOR_ANSWER_SCREEN = "Calculator Answer Screen",
}

export enum Action {
	FACEBOOK_SHARE = "Facebook share",
	WHATSAPP_SHARE = "Whatsapp share",
	TWITTER_SHARE = "Twitter share",
	GROOM_COUNT = "Groom selection count",
	BRIDE_COUNT = "Bride selection count",
	OTHER_GENDER_COUNT = "Other selection count",
	NO_BUDGET_COUNT = "No budget selection count",
	HAS_BUDGET_COUNT = "Has budget selection count",
	TOTAL_BUDGET_COUNT = "Total Number Of Budget Created",
	TOTAL_SUBSCRIBED_TO_UPDATES_COUNT = "Total Number Of User subscribed to updates",
	TOTAL_NOT_SUBSCRIBED_TO_UPDATES_COUNT = "Total Number Of User not subscribed to updates",
	HELP_FIND_VENDOR = "Help find vendor",
}

export enum Label {
	BUTTON = "Button",
	CUSTOM_LOCATION = "Custom location",
	CHECKBOX = "Checkbox",
}

export enum PlanType {
	CUSTOM = "CUSTOM",
	PREMIUM = "PREMIUM",
	ESSENTIAL = "ESSENTIAL",
	BASIC = "BASIC",
	FREE = "FREE",
}

export type InputData = {
	id: number;
	location: string;
	no_of_guests: number;
};

export type breakdownType = {
	item_code?: string;
	price?: number;
	item?: ModelResultItemBreakdown["item"];
	item_category?: string;
	checked?: boolean;
	sum_of_guests?: number;
	total_cost?: number;
	duration?: number;
	budget?: budgetForm["budget"] | string;
};

export type pricingPlanDetailsType = {
	name: string;
	email: string;
	plan_benefit: string;
	plan: string;
	feature_1: string;
	feature_2: string;
	feature_3: string;
};

export enum AuditType {
	general = "general",
	checklist = "checklist",
	budget_tracker = "budget_tracker",
}

export enum BudgetCategoriesType {
	"standard" = "standard",
	"mid_luxury" = "mid_luxury",
	"luxurious" = "luxurious",
}

export type paystackReferenceType = {
	reference: string;
	trans: string;
	status: string;
	message: string;
	transaction: string;
	trxref: string;
	redirecturl: string;
};

export type initializePaymentType = (callback?: ((reference: paystackReferenceType) => void) | undefined, onClose?: (() => void) | undefined) => void;

export type runModelBodyType = Array<{
	id: number;
	location: string;
	no_of_guests: number;
}>;


export type AsoebiAdminType = {
	magiclink: string;
	name: string;
	magiclink_id: string;
	hasSignup: boolean;
	email: string;
	questionnaire_ids: string[];
};

export enum RequestType {
	Success = "Success",
	Error = "Error",
}

export type AsoebiOptionType = Array<{ option: string; price: string; max_stock_qty: number; stock_bought_qty: number }>;

export enum AsoebiFormType {
	orderform = "orderform",
	tracker = "tracker",
}
