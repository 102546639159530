import { useQuery } from "@tanstack/react-query";

import { getEvents } from "@/axios/get-request";
import { useFilter } from "@/hooks/useFilter";
import { useModal } from "@/hooks/useModal";
import { useEvent } from "@/hooks/useEvent";
import { todoType } from "@/types";
import { useChecklist } from "@/hooks/useChecklist";

export default function useMyEvents() {
    const { ceremony, setCeremony } = useFilter();
    const { modal, modalDetails, setModalDetails, setModal } = useModal();
    const { selectedEventId, setSelectedEventId } = useEvent();
    const { setTodo } = useChecklist();

    const { status, data } = useQuery({
        queryKey: [`get_event_${selectedEventId}`],
        queryFn: () => getEvents(),
        refetchOnMount: true,
        onSuccess: ( data ) => {
            if (ceremony === null) {
                const activeEvent = selectedEventId ? data.result.filter((event) => event.id === selectedEventId) : data?.result;
                const defaultCeremony = activeEvent[0]?.ceremonies.length > 0 ? activeEvent[0]?.ceremonies[0] : null;
                if (defaultCeremony) {
                    setCeremony({
                        name: defaultCeremony?.name ? defaultCeremony?.name : "Main Wedding",
                        id: defaultCeremony.id,
                    });
                }
            }
            if (!selectedEventId && activeEvent) {
                setSelectedEventId(activeEvent[0]?.id);
            }
            const selectedEvent = selectedEventId ? data.result.filter((event) => event.id === selectedEventId) : data.result;
            if (!selectedEventId) {
                setSelectedEventId(selectedEvent[0]?.id);
            }
            if (selectedEvent[0]?.ceremonies && selectedEvent[0]?.ceremonies.length > 0) {
                const selectedCeremony =
                    ceremony?.id !== "all" ?
                        selectedEvent[0]?.ceremonies.filter((_ceremony) => _ceremony.id === ceremony?.id) :
                        selectedEvent[0]?.ceremonies;
                let allChecklist: todoType = [];
                selectedCeremony?.map((item) => {
                    allChecklist = item?.checklist ? [...allChecklist, ...item?.checklist] : [...allChecklist];
                });
                setTodo(allChecklist);
            }
        },
    });


    function closeModalHandler() {
        setModal(null);
        setModalDetails(null);
    }
    const activeEvent = status === "success" && data?.result ? data?.result?.filter((event) => event.id === selectedEventId) :
        data?.result ? data?.result: null;

    const selectedCeremonyData = activeEvent && activeEvent[0]?.ceremonies?.filter((_ceremony) => _ceremony.id === modalDetails?.ceremonyId)[0];

    const activeCollaborator = [];

    if (status === "success" && activeEvent && activeEvent[0]?.collaborators) {
        activeEvent[0]?.collaborators?.map((item) => {
            if (item.email && item.role) {
                activeCollaborator.push(item);
            }
        });
    }

    const activeCollaboratorCount = activeEvent && activeEvent[0]?.collaborators ? activeCollaborator?.length : null;

    return {
        activeCollaboratorCount,
        selectedCeremonyData,
        closeModalHandler,
        modal,
        data,
        status,
        activeEvent,
    };
}
