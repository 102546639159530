import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import type { ReactNode } from "react";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Heart1 from "@/assets/icon/Heart1";
import Heart2 from "@/assets/icon/Heart2";
import heart3 from "@/assets/img/heart3.svg";
import heart4 from "@/assets/img/heart4.svg";

// import wallpaper from "@/assets/img/wallpaper.png";

toast.configure();

const wallaperStyle = {
    // backgroundImage: `url(${wallpaper})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
};
interface Props {
	children: ReactNode;
}
export default function CalculatorWrapper({ children }: Props) {
    return (
        <>
            <Helmet>
                <title>Planaday Wedding Budget Calculator</title>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta
                    name="description"
                    content="Use Planaday to determine how much you will spend on your dream wedding"
                />
                <meta
                    name="keywords"
                    content="nigerian wedding, asoebi, ankara, lagos wedding, wedding budget, wedding cost, Planaday, plan an event, wedding planning,
                    event planning, event budgeting, event manager, event planner, wedding planner, planning assistant"
                />
                <meta property="og:title" content="Planaday Wedding Budget Calculator" />
                <meta
                    property="og:description"
                    content="Use Planaday to determine how much you will spend on your dream wedding"
                />
                <meta property="og:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta property="og:url" content="https://www.planaday.events" />
                <meta property="og:site_name" content="Planaday Events" />
                <meta property="og:type" content="website" />
                <meta property="og:type" content="Planaday Events" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Planaday Wedding Budget Calculator" />
                <meta
                    name="twitter:description"
                    content="Use Planaday to determine how much you will spend on your dream wedding"
                />
                <meta name="twitter:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:image:alt" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:site" content="@Planaday_events" />
                <meta name="twitter:creator" content="@Planaday_events" />
            </Helmet>
            <div>
                <Navbar />
                <div className="section-bar" />
                <main className="page" style={wallaperStyle}>
                    <div className="heart1">
                        <Heart1 />
                    </div>
                    <div className="heart2">
                        <Heart2 />
                    </div>
                    {children}
                    <div className="heart3">
                        <img src={heart3} alt="Heart shapes" />

                    </div>
                    <div className="heart4">
                        <img src={heart4} alt="Heart shapes" />
                    </div>
                </main>
                <div className="section-bar" />
                <Footer />
            </div>
        </>
    );
}
