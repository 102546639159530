import { Suspense } from "react";

import DashboardLayout from "@/layout/DashboardLayout";
import { useEvent } from "@/hooks/useEvent";
import EventOverviewLoader from "@/components/loaders/EventOverviewLoader";
import EventFilter from "@/components/EventFilter";
import AddNewCeremonyModal from "@/components/modals/AddNewCeremonyModal";
import EditCeremonyModal from "@/components/modals/EditCeremonyModal";
import AddCollaboratorModal from "@/components/modals/AddCollaboratorModal";
import useMyEvents from "@/hooks/useMyEvents";
import useTourGuide from "@/hooks/useTourGuide";
import EventDetails from "@/components/EventDetails";
import EventOverview from "@/components/EventOverview";
import CollaboratorListModal from "@/components/modals/CollaboratorListModal";
import useEventDetails from "@/hooks/useEventDetails";
import "@/styles/my-wedding.scss";
import useUser from "@/hooks/useUser";
import { PlanType } from "@/data/types";
import { CollaboratorPaymentPlan } from "@/types";

export default function MyEvents() {
    const { selectedEventId } = useEvent();
    const { activeCollaboratorCount, selectedCeremonyData, closeModalHandler, modal, data, status } = useMyEvents();
    const { status: userStatus, data: userData } = useUser();

    const _no_of_events = userStatus === "success" ? userData?.result?.event_ids?.length : 0;
    const no_of_events = _no_of_events as number;

    useTourGuide(userStatus, "my_events", userData?.result?.tour_guide?.event_overview, no_of_events);

    const event = status === "success" ? data?.result?.filter((event) => event.id === selectedEventId) : null;
    const selectedEvent = status === "success" && event ? event[0] : null;
    const eventName = status === "success" && event ? selectedEvent?.event_name : "";

    const { arrangedCollaborators, collaboratorsEmails } = useEventDetails(selectedEvent, userData?.result?.email as string);
    const canAddNewCollaboratorFreePlan = selectedEvent?.plan === PlanType.FREE && arrangedCollaborators.length < 2 ? true : false;
    const canAddNewCollaboratorPaidPlan =
        selectedEvent?.plan !== PlanType.FREE &&
        selectedEvent?.collaborator_per_events &&
        arrangedCollaborators.length < selectedEvent?.collaborator_per_events + 2 ?
            true :
            false;
    const canAddNewCollaborator = canAddNewCollaboratorPaidPlan || canAddNewCollaboratorFreePlan;
    const showCollaboratorListModal = modal === "show_event_collaborator";
    const collaboratorPremiumPlan =
        selectedEvent?.plan === PlanType.PREMIUM && selectedEvent?.collaborators ?
            (arrangedCollaborators?.length >= 4 ? CollaboratorPaymentPlan.PAID : PlanType.FREE) : PlanType.FREE;
    const collaboratorOtherPlan =
        selectedEvent?.plan !== PlanType.PREMIUM && selectedEvent?.collaborators ?
            (arrangedCollaborators?.length >= 2 ? CollaboratorPaymentPlan.PAID : PlanType.FREE) : PlanType.FREE;

    const collaboratorPaymentPlanStatus = selectedEvent?.collaborators ?
        selectedEvent?.plan === PlanType.PREMIUM ?
            collaboratorPremiumPlan :
            collaboratorOtherPlan :
        PlanType.FREE;

    const userEmail = userData?.result ? userData?.result?.email : "";

    const invitee = {
        name: userData?.result?.name,
        email: userData?.result?.email,
        role: userData?.result?.role,
    } as { name: string; role: string; email: string };

    return (
        <DashboardLayout title="My Events" showIcon>
            <Suspense fallback={<div>Loading...</div>}>
                {modal === "collaborator_modal" && userData?.result && (
                    <AddCollaboratorModal
                        show={modal}
                        onHide={closeModalHandler}
                        invitee={invitee}
                        collaboratorsEmails={collaboratorsEmails}
                        activeCollaboratorCount={activeCollaboratorCount}
                        canAddNewCollaborator={canAddNewCollaborator}
                        collaboratorPaymentPlanStatus={collaboratorPaymentPlanStatus}
                    />
                )}
                {modal === "add_ceremony_modal" && (
                    <AddNewCeremonyModal show={modal} onHide={closeModalHandler}
                        ceremonies={selectedEvent?.ceremonies} />
                )}
                {modal === "edit_ceremony_modal" && selectedCeremonyData ? (
                    <EditCeremonyModal show={modal} onHide={closeModalHandler} selectedCeremony={selectedCeremonyData} />
                ) : (
                    modal === "show_event_collaborator" &&
                    event &&
                    event?.length === 1 && (
                        <CollaboratorListModal
                            collaborators={event[0].collaborators}
                            title={event[0]?.event_name}
                            show={showCollaboratorListModal}
                            onHide={closeModalHandler}
                        />
                    )
                )}
            </Suspense>
            <section className="my-wedding">
                {status === "error" ? (
                    <p>Unable to fetch user details</p>
                ) : status === "loading" ? (
                    <EventOverviewLoader />
                ) : (
                    status === "success" &&
                    selectedEvent !== null && (
                        <>
                            <EventFilter eventName={eventName} showFilter={false} />
                            <EventDetails event={selectedEvent} userEmail={userEmail} />
                            <EventOverview event={selectedEvent} />
                        </>
                    )
                )}
            </section>
        </DashboardLayout>
    );
}
