import { useState } from "react";
import type { ChangeEvent } from "react";

import Input from "@/components/form/Input";
import Select from "@/components/form/Select";
import type { eventType } from "@/types";

const otherInput = {
    label: "Role",
    placeholder: "",
    name: "role",
};

interface Props {
	index: number;
	onChangeHandler: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
	item: {
		input: { label: string; placeholder: string; name: string };
		select: {
			label: string;
			name: string;
			options: string[];
		};
	};
	showLabel?: boolean;
	collaborator: eventType["collaborators"][0];
    updateCollaboratorDetails?: (name:string, value:string) => void
}

export default function CollaboratorInputRow({
    index, collaborator, onChangeHandler, item,
    updateCollaboratorDetails, showLabel = true, }: Props) {
    const counter = index + 1;
    const [inputType, setInputType] = useState("select");

    function collaboratorChangeHandler(
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        type: "input" | "select",
        index:number) {
        onChangeHandler(e);
        const _inputType = type === "select" && e.target.value === "Other" && inputType === "select" ? "input" : "select";
        const result = e.target.value !== "Other" && inputType === "input" ? "input" : _inputType;
        const _type = e.target.name === "role" ? result : inputType;
        setInputType(_type);
        const counter = index + 1;
        const detailsType = type === "input" ? "Email" : "Role";
        const name = `collaborator${counter}${detailsType}`;
        const value = e.target.value;
        if (updateCollaboratorDetails) {
            updateCollaboratorDetails(name, value,);
        }
    }

    const collaborator_email = collaborator.email as string;
    const __collaborator_role = collaborator.role as string;
    const collaborator_role = __collaborator_role === "Other" ? "" : __collaborator_role;

    const collaborator_input_test_id = `Collaborator_input__${counter}`;
    const collaborator_select_test_id = `Collaborator_select__${counter}`;
    const collaborator_other_test_id = `Collaborator_other__${counter}`;

    return (
        <div className="my-4 collaborator">
            {showLabel && <h5 className="mb-1">Collaborator {counter}</h5>}
            <div className="d-flex collaborator_group flex-lg-row flex-column justify-content-between mr-lg-5">
                <Input
                    className="col-lg-7 col-12 px-0"
                    input={item.input}
                    index={index}
                    testId={collaborator_input_test_id}
                    value={collaborator_email}
                    onChange={(e) => collaboratorChangeHandler(e, "input", index)}
                />
                {inputType.includes("select") ? (
                    <Select
                        className="col-lg-4 col-12 px-0"
                        input={item.select}
                        index={index}
                        testId={collaborator_select_test_id}
                        value={collaborator_role}
                        onChange={(e) => collaboratorChangeHandler(e, "select", index)}
                    />
                ) : (
                    <Input
                        className="col-lg-4 col-12 px-0"
                        input={otherInput}
                        index={index}
                        testId={collaborator_other_test_id}
                        value={collaborator_role}
                        onChange={(e) => collaboratorChangeHandler(e, "input", index)}
                    />
                )}
            </div>
        </div>
    );
}
