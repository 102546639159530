import { useEffect, useState } from "react";
import { addMonths } from "date-fns";
import type { ChangeEvent } from "react";

import { inviteUserPartner } from "@/axios/put-request";
import useUser from "@/hooks/useUser";
import useMyEvents from "@/hooks/useMyEvents";
import { CollaboratorPaymentPlan, CollaboratorPlanStatus } from "@/types";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";

type collaboratorDetailsType = {
    collaborator1Email: string,
    collaborator1Role: string,
    collaborator2Email: string,
    collaborator2Role: string,
}

export default function useCollaborator(
    collaboratorPaymentPlan: keyof typeof CollaboratorPaymentPlan,
    onboarding_event_id?:string,
    collaboratorDetails?: collaboratorDetailsType) {
    const { data, status, selectedEventId } = useUser();
    const { activeEvent } = useMyEvents();
    const planMonthDuration = activeEvent && activeEvent[0]?.plan_duration_month ? activeEvent[0]?.plan_duration_month : 0;

    const event_id = onboarding_event_id ? onboarding_event_id : selectedEventId;
    const [collaborator1, setCollaborator1] = useState({
        email: "",
        role: "",
        plan_status: "",
        payment_plan: "",
        plan_duration: planMonthDuration,
        send_plan_expiration_email: false,
        event_ids: [event_id],
        start_plan_date: "",
        end_plan_date: "",
    });
    const [collaborator2, setCollaborator2] = useState({
        email: "",
        role: "",
        plan_status: "",
        payment_plan: "",
        plan_duration: planMonthDuration,
        event_ids: [event_id],
        send_plan_expiration_email: false,
        start_plan_date: "",
        end_plan_date: "",
    });

    useEffect(() => {
        if (collaboratorDetails) {
            const collaboratorDetailsStorage =
        window.localStorage.getItem(LOCAL_STORAGE_KEYS.collaborator_details);
            const parsedCollaboratorDetails = collaboratorDetailsStorage ? JSON.parse(collaboratorDetailsStorage) : null;
            setCollaborator1({...collaborator1,
                email: parsedCollaboratorDetails ? parsedCollaboratorDetails.collaborator1Email : "",
                role: parsedCollaboratorDetails ? parsedCollaboratorDetails.collaborator1Role : "", });
            setCollaborator2({...collaborator2,
                email: parsedCollaboratorDetails ? parsedCollaboratorDetails.collaborator2Email : "",
                role: parsedCollaboratorDetails ? parsedCollaboratorDetails.collaborator2Role : "", });
        }
    }, []);

    function onChangeHandler1(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        setCollaborator1({
            ...collaborator1,
            [e.target.name]: e.target.value,
            payment_plan: collaboratorPaymentPlan,
            plan_status: CollaboratorPlanStatus.ACTIVE,
            start_plan_date: new Date().toISOString(),
            send_plan_expiration_email: false,
            end_plan_date: collaboratorPaymentPlan === CollaboratorPaymentPlan.FREE ? "" : addMonths(new Date(), planMonthDuration).toISOString(),
        });
    }

    function onChangeHandler2(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        setCollaborator2({
            ...collaborator2,
            [e.target.name]: e.target.value,
            payment_plan: collaboratorPaymentPlan,
            plan_status: CollaboratorPlanStatus.ACTIVE,
            start_plan_date: new Date().toISOString(),
            end_plan_date: collaboratorPaymentPlan === CollaboratorPaymentPlan.FREE? "" : addMonths(new Date(), planMonthDuration).toISOString(),
        });
    }

    async function inviteUserPartnerHandler(onboarding_id?:string, inviteeName?:string, inviteeRole?:string ) {
        if (onboarding_id && inviteeName && inviteeRole) {
            const collaborators = [collaborator1, collaborator2];
            const validCollaborators = collaborators.filter((collaborator) => collaborator.email);

            const inviteeEmail = data?.result?.email as string;
            await inviteUserPartner(onboarding_id, {
                eventId: selectedEventId,
                inviteeEmail,
                collaborators: validCollaborators,
                inviteeRole,
                inviteeName,
            });
        }
    }

    return {
        userData: status === "success" ? data?.result : null,
        collaborator1,
        collaborator2,
        onChangeHandler1,
        onChangeHandler2,
        inviteUserPartnerHandler,
    };
}
