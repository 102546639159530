import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import type { todoType, useChecklistType } from "@/types";

export const useChecklist = create<useChecklistType>((set) => ({
    checklistId: uuidv4(),
    updateChecklistId: (value: string) => set(() => ({ checklistId: value })),
    todo: [],
    setTodo: (value: todoType) => set(() => ({ todo: value })),
    checklistModal: null,
    setChecklistModal: (value) => set(() => ({ checklistModal: value })),
    selectectedChecklist: null,
    setSelectectedChecklist: (value) => set(() => ({ selectectedChecklist: value })),
}));
