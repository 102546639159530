import React, { useEffect, useState, useCallback } from "react";
import { Form, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

import Counter from "../components/Counter";
// import Bride from "../components/Bride";
// import Groom from "../components/Groom";
import Anonymous from "../components/Anonymous";
import checkbox from "../assets/img/check.svg";
import searchIcon from "../assets/img/search.svg";
import { Location, InputData, ScreenCategory, Action, Label, budgetForm } from "../data/types";
import { range, errorMessage } from "../helper";
import { eventTrack, budgetReport } from "../analytics";
import Loader from "./loader";
import checkbox_white from "../assets/img/checkbox_white.svg";
import type { onboardingFormDetailsType } from "@/types";
import Couple from "@/components/Couple";
import "@/styles/calculator.scss";
import PlannerIcon from "./PlannerIcon";

type FormProps = {
    onClick: (form: budgetForm, data: InputData[]) => void;
    saved:
        | {
                form: budgetForm;
                data: InputData[];
        }
        | undefined;
    isLoading: boolean;
    eventCeremonies?: onboardingFormDetailsType["ceremonies"]
};

const locations = [
    {
        id: 1,
        name: "Lagos - Mainland",
        value: Location.MAINLAND,
    },
    {
        id: 2,
        name: "Lagos - Island",
        value: Location.ISLAND,
    },
    {
        id: 3,
        name: "Abuja",
        value: Location.ABUJA,
    },
    {
        id: 4,
        name: "Port Harcourt",
        value: Location.PORT_HARCOURT,
    },
    {
        id: 5,
        name: "Ibadan",
        value: Location.IBADAN,
    },
    { id: 6, name: "Other", value: Location.OTHER },
];

export default function CalculatorForm({ onClick, saved, isLoading, eventCeremonies }: FormProps) {
    const [form, setForm] = useState({
        user: "",
        budget: 0,
        hasBudget: true,
        days: 1,
    });
    const [data, setData] = useState([
        {
            id: 1,
            location: "",
            no_of_guests: 0,
        },
    ]);
    const [options, setOptions] = useState([
        {
            id: 1,
            name: "Planner",
            selected: false,
            color: "#CB3050",
        },
        {
            id: 2,
            name: "Couple",
            selected: false,
            color: "#CB3050",
        },
        {
            id: 3,
            name: "Other",
            selected: false,
            color: "#CB3050",
        },
    ]);

    const [search, setSearch] = useState("");
    const [otherLocation, setOtherLocation] = useState<string[]>([]);
    const [oneLocation, setOneLocation] = useState("");

    async function updateOnboarding() {
        if (eventCeremonies && eventCeremonies?.length > 0) {
            setForm({
                ...form,
                days: eventCeremonies ? eventCeremonies?.length : 1,
            });
            const defaultData = new Array(eventCeremonies?.length).fill(0);
            const daysOfEventsData: Array<{
                        id: number;
                        location: string;
                        no_of_guests: number;
                        name: string;
                    }> = [];
            defaultData.map((_, index) => {
                const eventIndex = index + 1;
                daysOfEventsData.push({
                    id: eventIndex,
                    location: "",
                    no_of_guests: 0,
                    name: eventCeremonies[index].label,
                });
            });

            setData(daysOfEventsData);
        }
    }

    useEffect(() => {
        updateOnboarding();
    }, [eventCeremonies]);

    /**
     * Set color of button
     * @param id number
     * @param val string
     */
    const setColor = useCallback(
        (id: number, val: string) => {
            const arr = [...options];
            if (arr[id].selected && val === "#CB3050") return;
            else {
                arr[id].color = val;
                setOptions(arr);
            }
        },
        [options],
    );

    /**
     * Set selected user
     * @param id number
     */
    const setSelected = useCallback(
        (id: number) => {
            if (!options[id].selected) {
                setColor(id, "white");
                options.forEach((option, index) => {
                    if (option.selected) {
                        option.selected = false;
                        setColor(index, "#CB3050");
                    }
                });
            } else setColor(id, "#CB3050");
            const arr = [...options];
            arr[id].selected = !arr[id].selected;
            setOptions(arr);
            setForm({ ...form, user: arr[id].name });
        },
        [form, options, setColor],
    );

    /**
     * Set budget
     * @param {string} value
     */
    const setBudget = (value: string) => {
        let valueCopy = Number(value);
        if (valueCopy < 0) {
            valueCopy = 0;
        } else {
            valueCopy = parseFloat(value.replace(/,/g, ""));
            if (isNaN(valueCopy) === true) {
                valueCopy = 0;
            }
        }
        setForm({ ...form, budget: valueCopy });
    };

    /**
     * Set guest
     * @param {number} id number
     * @param {string} value string
     */
    const setGuest = (id: number, value: string) => {
        const arrCopy = [...data];
        let valueCopy = Number(value);
        if (valueCopy < 0) {
            valueCopy = 0;
        } else {
            valueCopy = parseFloat(value?.replace(/,/g, ""));
        }
        const index = arrCopy.findIndex((option) => option.id === id);
        if (isNaN(valueCopy) === true) {
            valueCopy = 0;
        }
        arrCopy[index].no_of_guests = valueCopy;
        setData(arrCopy);
    };

    /**
     * Set location
     * @param {number} id number
     * @param {object} value string
     */
    const setLocation = (id: number, value: string) => {
        const arrCopy = [...data];
        const index = arrCopy.findIndex((option) => option.id === id);
        arrCopy[index].location = value;
        setData(arrCopy);
    };

    const sortCustomLocation = (custom_locations: string[]) => {
        const sorted_custom_locations: string[] = [];
        custom_locations.map((location: string) => {
            if ((sorted_custom_locations.includes(location) || location === "") !== true) {
                sorted_custom_locations.push(location);
            }
        });
        return sorted_custom_locations;
    };
    /**
     * Set number of days
     * @param {number} days
     */
    const setDays = (days: number) => {
        const arrCopy = [...data];
        const info = {
            id: 0,
            location: "",
            no_of_guests: 0,
        };
        if (days > form.days) {
            for (let i = 0; i < days - form.days; i += 1) {
                info.id = arrCopy[arrCopy.length - 1].id + 1;
                const copyInfo = Object.assign({}, info);
                arrCopy.push(copyInfo);
            }
        } else {
            for (let i = 0; i < form.days - days; i++) {
                arrCopy.pop();
            }
        }
        setData([...arrCopy]);
        setForm({ ...form, days });
    };

    const validateData = () => {
        if (!form.user) {
            errorMessage("Please select who you are - Planner, Couple or Other");
            return;
        } else if (!form.budget && form.hasBudget) {
            errorMessage("Please check your budget");
            return;
        }
        for (let i = 0; i < data.length; i += 1) {
            if (data[i].location === "") {
                errorMessage("Please check your wedding location(s)");
                return;
            } else if (data[i].no_of_guests === 0) {
                errorMessage("Please add your expected number of guests");
                return;
            }
        }
        data.map((data: InputData) => {
            const location = data.location;
            if (location === "Other") {
                data.location = oneLocation;
            }
        });

        onClick(form, data);
        budgetReport(form);
        eventTrack(ScreenCategory.BUDGET_FORM_SCREEN, Action.TOTAL_BUDGET_COUNT, Label.BUTTON);
        const customLocation = sortCustomLocation(otherLocation);
        if (customLocation.length > 0) {
            eventTrack(ScreenCategory.BUDGET_FORM_SCREEN, customLocation[0], Label.CUSTOM_LOCATION);
        }
    };

    const textLocation = (location: string) => {
        if (location === "mainland" || location === "island") {
            return `Lagos - ${location}`;
        }
        return location;
    };

    const handleUnlistedLocations = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oneLocation = event.target.value;
        setOneLocation(oneLocation);
    };
    const saveOtherLocations = () => {
        setOtherLocation((prevState) => [...prevState, oneLocation]);
    };
    const displayLocation = (option: InputData) => {
        if (option.location === "") {
            return <Dropdown.Toggle className="dropdown-btn">Select city</Dropdown.Toggle>;
        } else if (option.location === "Other") {
            return (
                <>
                    <input
                        type="text"
                        className="input-btn-location"
                        onChange={handleUnlistedLocations}
                        onMouseLeave={saveOtherLocations}
                        onBlur={saveOtherLocations}
                    />
                    <span className="px-2">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="budget" className="m-3">
                                    We are still conducting research for other locations in Nigeria not on the list. We will keep updating our
                                    location list and prices.
                                </Tooltip>
                            }>
                            <div className="question">
                                <span>?</span>
                            </div>
                        </OverlayTrigger>
                    </span>
                </>
            );
        } else {
            return <Dropdown.Toggle className="dropdown-btn location-btn">{textLocation(option.location)}</Dropdown.Toggle>;
        }
    };
    useEffect(() => {
        if (saved) {
            const { form: savedForm, data } = saved;
            if (form && data) {
                const index = options.findIndex((option) => option.name === savedForm.user);
                setSelected(index);
                setData(data);
                setForm({ ...savedForm });
            }
        }
    }, [saved]);

    const formClassname = form.days > 1 ? "d-flex justify-content-between days-form" : "";

    const isLoadingClassname = isLoading ? "calc-btn-load " : "calc-btn";

    return (
        <div className="section">
            <ToastContainer position="top-center" autoClose={2000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} />
            <div className="budget_calculator_banner bg-white py-4 px-lg-5 px-3 pb-2 mb-5 rounded">
                <h5 className="mb-0 small font-weight-bold text-center">
					Make Event Planning a Breeze, with our new {" "}
                    <a target="_blank" href="https://planaday.events?utm_source=budget+calculator+&utm_medium=redirect">Budget tracker</a>,
                    {" "}<a target="_blank" href="https://planaday.events?utm_source=budget+calculator+&utm_medium=redirect">Checklist</a> and
                    {" "} <a target="_blank"
                        href="https://tools.planaday.events/asoebi?utm_source=budget+calculator+&utm_medium=redirect">Aso Ebi Tracker</a> tools
                </h5>
            </div>
            <h1 className="page__header">Let’s get to know you a bit better!</h1>
            <div className="page__content">
                <div className="form">
                    <Form.Row className="mb-3">
                        <Form.Group>
                            <Form.Label>Who are you?</Form.Label>
                            <div className="d-flex justify-content-between option-select who-are-you">
                                <button className={`btn input-btn ${options[0].selected ? "selected" : ""}`} onClick={() => setSelected(0)}>
                                    <PlannerIcon />
                                    <span>{options[0].name}</span>
                                </button>

                                <button className={`btn input-btn groom ${options[1].selected ? "selected" : ""}`} onClick={() => setSelected(1)}>
                                    <Couple color={options[1].color} />
                                    <span>{options[1].name}</span>
                                </button>

                                <button className={`btn input-btn ${options[2].selected ? "selected" : ""}`} onClick={() => setSelected(2)}>
                                    <Anonymous color={options[2].color} />
                                    <span>{options[2].name}</span>
                                </button>
                            </div>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="mb-3">
                        <Form.Group>
                            <Form.Label>
								What is your ideal budget?
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="budget">
											You are not looking to spend beyond this amount, except Bank of mum and dad says otherwise
                                        </Tooltip>
                                    }>
                                    <div className="question">
                                        <span>?</span>
                                    </div>
                                </OverlayTrigger>
                            </Form.Label>
                            {form.hasBudget ? (
                                <Counter
                                    placeholder="50,000,000"
                                    no={form.budget.toString()}
                                    setValue={(val: string) => setBudget(val.toString())}
                                    add={() => setBudget((form.budget += 100000).toString())}
                                    minus={() => setBudget((form.budget -= 100000).toString())}
                                />
                            ) : (
                                <div className="input-btn no-budget">No budget</div>
                            )}
                            <div className="custom-check">
                                {form.hasBudget ? (
                                    <img
                                        className="checkbox white_checkbox"
                                        src={checkbox_white}
                                        alt="checkbox"
                                        onClick={() =>
                                            setForm({
                                                ...form,
                                                hasBudget: false,
                                            })
                                        }
                                    />
                                ) : (
                                    <img
                                        className="checkbox"
                                        src={checkbox}
                                        alt="checkbox"
                                        onClick={() =>
                                            setForm({
                                                ...form,
                                                hasBudget: true,
                                            })
                                        }
                                    />
                                )}
                                <p>I don't have a budget</p>
                            </div>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="mb-3">
                        <Form.Group>
                            <Form.Label>How many days for your wedding?</Form.Label>
                            <Dropdown>
                                <Dropdown.Toggle className="dropdown-btn days_to_wedding" id="dropdown-basic">
                                    {form.days}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {range(1, 8).map((option, index) => {
                                        return (
                                            <Dropdown.Item className="dropdown-option" key={index} onClick={() => setDays(option)}>
                                                {option}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </Form.Row>

                    {data.map((option, index) => {
                        const rowClassname = form.days > 1 ? "" : "mb-3";
                        return (
                            <div key={option.id} className="mb-5 location">
                                {form.days > 1 && <p className="days">Day {index + 1}</p>}
                                <div className={formClassname}>
                                    <Form.Row className={rowClassname}>
                                        <Form.Group>
                                            <Form.Label>Location of your event?</Form.Label>
                                            <Dropdown>
                                                {displayLocation(option)}
                                                <Dropdown.Menu>
                                                    <div className="search">
                                                        <div className="d-flex">
                                                            <img src={searchIcon} alt="search" />
                                                            <Form.Control
                                                                value={search}
                                                                placeholder="Search"
                                                                aria-label="search"
                                                                onChange={({ target: { value } }) => setSearch(value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {locations
                                                        .filter((location) => location.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
                                                        .map((option1, index1) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    key={index1}
                                                                    className="dropdown-option major_cities"
                                                                    onClick={() => {
                                                                        setLocation(option.id, option1.value);
                                                                        setSearch("");
                                                                    }}>
                                                                    {option1.name}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row className="guests">
                                        <Form.Group>
                                            <Form.Label>Number of guests you are expecting?</Form.Label>
                                            <div className="">
                                                <Counter
                                                    placeholder="100"
                                                    hasIcon={false}
                                                    maxLength={5}
                                                    no={data[index].no_of_guests.toString()}
                                                    setValue={(val: string) => setGuest(option.id, val.toString())}
                                                    add={() => setGuest(option.id, (data[index].no_of_guests + 100).toString())}
                                                    minus={() => setGuest(option.id, (data[index].no_of_guests - 100).toString())}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                </div>
                            </div>
                        );
                    })}
                    <div className="d-flex justify-content-center">
                        <button className={`${isLoadingClassname} btn primary-btn desktop-calculate-btn`} type="submit" onClick={validateData}>
                            {isLoading ? (
                                <>
									Calculating <Loader />
                                </>
                            ) : (
                                <>
									Calculate
                                </>
                            )}
                        </button>

                        <button
                            className="mobile-calculate-btn btn calc-btn primary-btn"
                            type="submit"
                            onClick={validateData}
                            disabled={isLoading ? true : false}>
							Calculate
                            {isLoading && <Loader />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
