import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import CalculatorForm from "@/components/CalculatorForm";
import { run_model } from "@/axios/post-request";
import { updateOnboardingDetails } from "@/axios/put-request";
import { getUID } from "@/axios/config";
import type { InputData, budgetForm } from "@/data/types";
import CalculatorWrapper from "@/components/CalculatorWrapper";
import { onboardingDetailsStorageType } from "@/types";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";

type formType = {
	form: budgetForm;
	data: InputData[];
};

type eventCeremoniesType = Array<{ label: string; value: string }> | [];

export default function CalculateEventBudget() {
    const containerRef = useRef<HTMLDivElement>(null);
    const [form, setForm] = useState<formType>();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const user_id = getUID();
    const [eventCeremonies, setEventCeremonies] = useState<eventCeremoniesType>([]);

    function updateDefaultOnboardingDetails(onboarding_details: onboardingDetailsStorageType) {
        if (onboarding_details) {
            const __ceremonies = onboarding_details?.ceremonies ? onboarding_details?.ceremonies : [];
            const ceremonies = __ceremonies as Array<{ label: string; value: string }> | [];
            setEventCeremonies(ceremonies);
        }
    }

    function prefillCalculatorForm(calculatorDetails: formType | null) {
        if (calculatorDetails) {
            setForm(calculatorDetails);
        }
    }

    useEffect(() => {
        const existingOnboardingDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.onboarding_details);
        const existingCalculatorDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.calculator_details);
        const onboardingDetailsStorage = existingOnboardingDetails ? JSON.parse(existingOnboardingDetails) : null;
        const calculatorDetailsStorage = existingCalculatorDetails ? JSON.parse(existingCalculatorDetails) : null;

        updateDefaultOnboardingDetails(onboardingDetailsStorage);
        prefillCalculatorForm(calculatorDetailsStorage);
    }, []);

    async function calculateBudget(data: InputData[], formData: budgetForm) {
        try {
            setLoading(true);
            const answer = await run_model(data);
            if (answer) {
                setLoading(false);
                window.localStorage.setItem(LOCAL_STORAGE_KEYS.calculator_details, JSON.stringify({form: formData, data}));
                window.localStorage.setItem(LOCAL_STORAGE_KEYS.model_result, JSON.stringify(answer));
                navigate("/onboarding/view-event-budget");
            }
        } catch (error: unknown) {
            const _error = error as { message: string };
            setLoading(false);
            toast.error(_error.message || "An error occurred, try again later!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    async function onClickCalculatorHandler(form: budgetForm, data: InputData[]) {
        setForm({ form, data });
        calculateBudget(data, form);
        if (data && eventCeremonies.length > 0) {
            await updateOnboardingDetails(user_id, {
                calculator_details: {
                    id: user_id,
                    role: form.user,
                    budget: form.budget,
                    hasBudget: form.hasBudget,
                    days: form.days,
                    data,
                },
                completed: false,
            });
        }
    }

    return (
        <CalculatorWrapper>
            <div className="container calculator_view" ref={containerRef}>
                <CalculatorForm
                    saved={form}
                    onClick={(form: budgetForm, data: InputData[]) => onClickCalculatorHandler(form, data)}
                    isLoading={loading}
                    eventCeremonies={eventCeremonies}
                />
            </div>
        </CalculatorWrapper>
    );
}
