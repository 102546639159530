import DashboardLayout from "@/layout/DashboardLayout";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import BudgetBreakdown from "@/components/BudgetBreakdown";
import BudgetList from "@/components/BudgetList";
import BudgetSummary from "@/components/BudgetSummary";
import { getBudget } from "@/axios/get-request";
import ChecklistLoader from "@/components/loaders/ChecklistLoader";
import CeremonyFilter from "@/components/CeremonyFilter";
import CardLoader from "@/components/loaders/CardLoader";
import { useFilter } from "@/hooks/useFilter";
import useTourGuide from "@/hooks/useTourGuide";
import useUser from "@/hooks/useUser";
import type { categoryType, costItemObjectType } from "@/types";
import "@/styles/budget-tracker.scss";
import { addDefaultBudget } from "@/axios/post-request";

export default function BudgetTrackerPage() {
    const [similarCostItems, setSimilarCostItems] = useState<costItemObjectType["cost_items"]>([]);
    const { ceremony } = useFilter();
    const { data: userData, status: userStatus, selectedEventId } = useUser();

    const { data, status } = useQuery({
        queryKey: [`get_categories_${selectedEventId}`],
        queryFn: () => getBudget(selectedEventId),
        onSuccess: (data) => {
            if (!data.data.result) {
                addDefaultBudgetRequest();
            }
        }
    });
    const _no_of_events = userStatus === "success" ? userData?.result?.event_ids?.length : 0;
    const no_of_events = _no_of_events as number;

    useTourGuide(userStatus, "budget_tracker", userData?.result?.tour_guide?.budget_tracker, no_of_events);

    const categoriesArray: categoryType[] | null = status === "success" ? data?.data?.result?.categories : null;

    let costItems: costItemObjectType[] = [];

    if (status === "success" && categoriesArray) {
        categoriesArray.map((item) => {
            const costItem = {
                cost_items: item.cost_items,
                categoryId: item.id,
                categoryName: item.name
            };
            costItems = [...costItems, costItem];
        });
    }

    let allCostItems: costItemObjectType["cost_items"] = [];

    useEffect(() => {
        if (categoriesArray && status === "success") {
            categoriesArray.map((item) => {
                allCostItems = [...allCostItems, ...item.cost_items];
            });
            const costItemsWithSameTags = ceremony?.id !== "all" ? allCostItems.filter((item) => item.tag === ceremony?.id) : allCostItems;
            setSimilarCostItems(costItemsWithSameTags);
        }
    }, [ceremony?.id, status]);

    const totalActualCost = similarCostItems.reduce((a, b) => a + b.actual, 0);
    const totalPaidCost = similarCostItems.reduce((a, b) => a + b.paid, 0);
    const totalEstimate = similarCostItems.reduce((a, b) => a + b.estimate, 0);

    async function addDefaultBudgetRequest() {
        await addDefaultBudget([{ id: 1, location: "Abuja", no_of_guests: 100 }], selectedEventId);
    }
    return (
        <DashboardLayout title="Budget Tracker">
            <section className="budget_tracker">
                <CeremonyFilter text="Event Budget" />
                {status === "error" ? (
                    <p>error fetching budget</p>
                ) : status === "loading" ? (
                    <CardLoader height={100} width="100%" className="mb-4" />
                ) : (
                    <BudgetSummary budgetActualCost={totalActualCost} budgetEstimate={totalEstimate} budgetPaid={totalPaidCost} />
                )}
                <BudgetBreakdown costItems={costItems}>
                    {status === "error" ? (
                        <p>Error fetching categories</p>
                    ) : status === "loading" ? (
                        <ChecklistLoader />
                    ) : (
                        <div className="budget_breakdown_view bg-white">
                            {categoriesArray ? (
                                categoriesArray &&
								categoriesArray.map((category, index) => <BudgetList key={category.id} index={index} category={category} />)
                            ) : (
                                <p>Add a Budget Breakdown</p>
                            )}
                        </div>
                    )}
                </BudgetBreakdown>
            </section>
        </DashboardLayout>
    );
}
