import { getAuth } from "firebase/auth";
import { ChangeEvent, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import useAuth from "@/hooks/useAuth";
import Input from "@/components/form/Input";
import PasswordInput from "@/components/form/PasswordInput";
import Button from "@/components/Button";
import editProfileFormContent from "@/json/form/edit_profile.json";
import DashboardLayout from "@/layout/DashboardLayout";
import "@/styles/edit-profile.scss";
import { updateAuditUsername } from "@/axios/put-request";
import useUser from "@/hooks/useUser";

export default function EditProfile() {
    const { updateUserProfile } = useAuth();
    const auth = getAuth();
    const { data, selectedEventId } = useUser();
    const user = auth.currentUser;
    const [profile, setProfile] = useState({
        name: data?.result?.name || user?.displayName || "",
        email: data?.result?.email || "",
        password: "",
    });
    const user_id = data?.result?.id as string;
    const { mutate, isLoading } = useMutation({
        mutationKey: ["editUserProfile"],
        mutationFn: () => {
            return updateUserProfile(profile.password, {
                name: profile.name,
                email: profile.email,
            });
        },
        onSuccess: (data) => {
            if (data) {
                toast.success("Profile updated");
                if (profile.name) {
                    updateAuditUsername(profile.name, selectedEventId, user_id);
                }
            }
        },
        onError: () => {
            toast.error("Error updating profile");
        },
    });

    function editProfile() {
        if (profile.password && profile.password.length < 6) {
            return toast.error("Please choose a secure password");
        }
        mutate();
    }

    function onInputChange(e: ChangeEvent<HTMLInputElement>) {
        setProfile({ ...profile, [e.target.name]: e.target.value });
    }

    return (
        <DashboardLayout title="Edit Profile">
            <section className="edit_profile">
                <h1>Edit Profile</h1>
                <form>
                    <Input input={editProfileFormContent.name} value={profile.name} onChange={onInputChange} />
                    <Input input={editProfileFormContent.email} value={profile.email} readOnly onChange={onInputChange} />
                    <PasswordInput input={editProfileFormContent.password} value={profile.password} onChange={onInputChange} />
                    <p>
						Do you want to upgrade your plan? <Link to="/dashboard/pricing">See Pricing</Link>
                    </p>
                    <div className="button_wrapper">
                        <Button text="Save Changes" className="bg_red text-white" isLoading={isLoading} onClick={editProfile} />
                    </div>
                </form>
            </section>
        </DashboardLayout>
    );
}
